.kt-accordion-inner-wrap {
  padding-bottom: 20px;

  .wp-block-kadence-pane {
    background: $color-white-1 !important;
    margin-bottom: 24px;

    .kt-blocks-accordion-header {
      min-height: 118px;
      display: flex;
      //justify-content: center;
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        // IE10+
        padding-top: 38px;
      }
      background: $color-white-1 !important;

      span:nth-child(1) {
        font-size: 20px;
        line-height: 30px;
        padding-left: 13px;
        color: $color-blue-3;
        @include breakpoint3 {
          padding-left: 0;
        }
      }

      span:nth-child(2) {
        background: $color-blue-1;
        padding: 8px;

        &:before,
        &:after {
          top: 20px;
          left: 10px;
          height: 2px;
          background: $color-white-1 !important;
        }
      }

      @include breakpoint3 {
        min-height: 137px;
      }
    }

    .kt-accordion-panel-active {
      background: $color-white-1 !important;
    }
  }

  .kt-accordion-panel-inner {
    padding-left: 41px;
    padding-top: 0;

    p {
      font-size: 16px;
      line-height: 30px;
      padding-right: 133px;
    }

    @include breakpoint3 {
      padding-left: 15px;
      p {
        padding-right: 80px;
      }
    }
  }
}

//cover
.cover-page {
  position: relative;
  background-size: cover;
  position: relative;
  display: flex;
  // background-image: url("../images/faq.png");
  margin-bottom: 66px;

  img {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: -1;
    object-fit: cover;
    height: 352px;
    -webkit-object-fit: cover;
    -moz-object-fit: cover;
    -o-object-fit: cover;
    -ms-object-fit: cover;
  }

  height: 352px;

  .cover-page__title {
    text-align: center;
    line-height: 352px;
    color: #fff;

    &.spec {
      padding-top: 10px;
      line-height: 178px;
    }
  }

  @include breakpoint3 {
    align-items: center;
    padding-top: 0;
    .cover-page__title {
      font-size: 30px;
      line-height: 40px;
    }
  }
}

.list-horizon {
}

.list-vertical {
}

//form
form {

  .form-group {
    .wpcf7-form-control-wrap{
      position: static;
    }
    margin-bottom: 71px;
    position: relative;
    input.error, select.error, .wpcf7-not-valid {
      background-color: #feeeee !important;
    }
    label.error, .wpcf7-not-valid-tip {
      position: absolute;
      color: red;
      top: 0px;
      font-size: 12px;
      right: 64px
    }
  }

  ::placeholder {
    color: #636363;
    font-size: 16px;
    font-style: normal;
    line-height: 30px;
  }

  .form-control {
    background-color: #e8f7f8;
    width: 100%;
    height: 55px;
    padding-left: 27px;
    padding-right: 23px;
    border: none;
  }

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
    outline: 0;
    box-shadow: none;
    border: 0 !important;
    background-image: none;
    background-color: #e8f7f8;
    color: #636363;
    font-size: 16px;
    font-style: normal;
    width: 100%;
    height: 55px;
    padding-left: 27px;
    padding-right: 23px;
  }

  .form-select::after {
    font-family: FontAwesome;
    content: "\f0dd";
    position: absolute;
    top: 43%;
    right: 35px;
    cursor: pointer;
    pointer-events: none;
    -webkit-transition: 0.25s all ease;
    -o-transition: 0.25s all ease;
    transition: 0.25s all ease;
    transform: translateY(-43%);
  }

  textarea {
    resize: none;
    background-color: #e8f7f8;
    width: 100%;
    padding-left: 30px;
    height: 55px;
    padding-top: 14px;
    padding-right: 23px;
  }

  input.button {
    width: 197px;
    height: 70px;
    font-size: 24px;
    color: #B1E4E8;
    background: #37677E;
    margin: auto;
  }

  p {
    display: block;
    margin: auto
  }
}

.gm-style .place-card-large {
  display: none !important;
}