.invisalign-ph {
  .cover-page {
    margin-bottom: 0;
    &__title {
      display: none;
    }
  }
  background-color: $color-dark-4;
  .video {
    margin-top: -166px;
    &__text {
      background-color: $color-white-1;
      text-align: center;
      height: 498px;
      padding: 63px 223px 302px 217px;
      .text_line_custom {
        font-size: 25px;
        color: $color-blue-3;
        position: relative;
        text-transform: uppercase;
        text-align: left;
        display: inline-block;
        line-height: 40px;
        z-index: 99;
        &::after {
          content: "";
          width: 296px;
          height: 17px;
          background-color: #d8f1f3;
          display: block;
          position: absolute;
          top: 18px;
          z-index: -1;
          left: 50%;
          transform: translateX(-50%);
        }
      }
      p {
        line-height: 30px;
        padding-top: 10px;
      }
    }
    &__img {
      position: relative;
      img {
        left: 50%;
        transform: translateX(-50%);
        position: relative;
        margin-top: -275px;
        padding: 0 60px;
      }
      @include playbutton(114px, 35px);
      .round-button {
        top: 4%;
      }
    }
  }
  .content {
    padding: 57px 0;
    &__top {
      padding: 20px 0;
      .row {
        padding: 0 60px;
      }
      .text {
        padding-right: 100px;
        @include textLine(0);
        display: flex;
        align-items: center;
        p {
          text-align: left;
          line-height: 30px;
          margin-top: 15px;
        }
      }
      .img {
        img {
          float: right;
        }
      }
    }
    &__bottom {
      padding: 20px 0;
      .row {
        padding: 0 60px;
      }
      .text {
        @include textLine(0);
        display: flex;
        align-items: center;
        padding-left: 70px;
        p {
          text-align: left;
          line-height: 30px;
          margin-top: 15px;
        }
      }
    }
  }
  @include breakpoint3 {
    background-color: unset;
    .video {
      margin-top: 0px;
      &__text {
        background-color: unset;
        text-align: center;
        height: unset;
        padding: 32px 0 0 0;
        p {
          line-height: 30px;
          margin-top: 17px;
        }
      }
      &__img {
        img {
          left: unset;
          transform: unset;
          position: relative;
          margin-top: 27px;
          padding: 0px;
         
        }
        @include playbutton(64px, 9px);
        .round-button {
          top: 59%;
        }
      }
    }
    .content {
      padding: 16px 0;
      &__top {
        padding: 20px 0;
        .row {
          padding: 0px;
        }
        .text {
          padding-right: 0px;
          p {
            margin-bottom: 16px;
          }
        }
        .img {
          img {
            float: unset;
            width: 100%;
          }
        }
      }
      &__bottom {
        padding: 20px 0;
        .row {
          padding: 0px;
        }
        .text {
          padding-left: 0;
          p {
            margin-bottom: 16px;
          }
        }
      }
    }
  }
}
