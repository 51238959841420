.about-us-ph {
  .about_video {
    margin-left: 15px;
    img {
      margin-top: 30px;
      width: 100%;
    }
  }

  .cover-page {
    margin-bottom: 0;
    height: 300px;

    img {
      height: 300px;
    }

    &__title {
      line-height: 30px;
      padding-top: 122px;
    }
  }

  @include breakpoint4 {
    .container {
      &--custom {
        max-width: 1400px;
      }
    }
  }

  .collum {
    position: relative;

    &::after {
      content: "";
      width: 518px;
      height: 518px;
      background-image: url(./../../icons/table.svg);
      position: absolute;
      bottom: 0;
      right: 0;
    }

    margin-left: 0;
    padding-left: 0;

    &__left {
      padding-top: 76px;
      padding-left: 63px;
      margin-left: -70px;
      background-color: $color-blue-1;
@media screen  and (max-width: 1157px){
a{
  font-size: 13px;
}
}
      &__1,
      &__2,
      &__3 {
        display: block;
        color: #37677e;
        padding-left: 45px;
        font-size: 20px;

        line-height: 81px;
      }

      &__1 {
        &::before {
          content: "";
          background-image: url(./../../icons/about1.svg);
          width: 33px;
          height: 37px;
          display: inline-block;
          margin-right: 39px;
          margin-bottom: -10px;
        }
      }

      &__2 {
        &::before {
          content: "";
          background-image: url(./../../icons/aboutus3.svg);
          width: 36px;
          height: 40px;
          display: inline-block;
          margin-right: 36px;
          margin-bottom: -14px;
        }
      }

      &__3 {
        &::before {
          content: "";
          background-image: url(./../../icons/aboutus2.svg);
          width: 33px;
          height: 37px;
          display: inline-block;
          margin-right: 39px;
          margin-bottom: -10px;
          background-repeat: no-repeat;
          background-position: center;
        }
      }
    }

    &__right {
      .about {
        // padding-left: ;
        &__left {
          padding-right: 53px;

          &__text {
            padding-top: 48px;

            .link {
              a {
                display: inline-block;
                text-decoration: underline;
                color: $color-blue-3;
                line-height: 28px;
                margin-bottom: 10px;
                font-weight: 500;
              }
            }

            &__name {
              font-size: 60px;
              font-family: "Pacifico", sans-serif;
              line-height: 61px;
              color: $color-blue-3;
            }

            &__des {
              font-size: 25px;
              font-weight: normal;
              font-family: "Pacifico", sans-serif;
              color: $color-blue-3;
              line-height: 61px;
              margin-top: 10px;
            }

            @include textLine(0);

            &__content {
              line-height: 35px;
              color: $color-dark-1;
            }
          }
        }

        &__right {
          img {
            max-width: 100%;
            margin-top: -90px;
          }
        }
      }
      .about_below {
        &__left {
          padding-right: 75px;
          padding-top: 30px;
          @include textLine(0);

          &__content {
            color: $color-dark-1;
          }
          .descreption {
            margin-top: 35px;
            margin-left: 25px;
            position: relative;
            &::before {
              position: absolute;
              left: -25px;
              top: 25px;
              content: "";
              background-image: url(./../../icons/Vector.svg);
              background-size: cover;
              background-position: center;
              background-repeat: no-repeat;
              display: inline-block;
              width: 11px;
              height: 13px;
            }
            &__title {
              font-size: 20px;
              color: $color-blue-3;
            }
          }
        }

        &__right {
          padding-top: 41px;
          @include textLine(0);
          // @include text_line(105%);
          &__body {
            padding-top: 20px;
            line-height: 35px;
            color: $color-dark-1;
          }

          .list {
            padding-top: 11px;

            p {
              line-height: 53px;

              &::before {
                margin-right: 23px;
                content: "";
                background-image: url(./../../icons/Vector.svg);
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
                display: inline-block;
                width: 11px;
                height: 13px;
              }
            }
          }
        }
      }
      .ourside {
        padding-left: 15px;
        padding-top: 35px;
        padding-bottom: 60px;
        // position: relative;

        // @include text_line(355px);
        @include textLine(0);

        &__body {
          line-height: 35px;
          margin-top: 14px;
          padding-right: 26%;
        }
      }
    }
  }
  .choose {
    background-color: #f3f2f0;
    .content1 {
      &__left {
        background-color: #b1e4e8;
        margin-left: -70px;
      }

      &__right {
        padding-top: 50px;
        padding-left: 15px;

        .item_1 {
          margin-bottom: 50px;

          .text {
            p {
              line-height: 35px;
              font-size: 16px;
              padding: 59px 55px 114px 55px;

              span {
                font-weight: 400;
                font-size: 20px;
                color: #636363;
              }
            }

            background-position: center;
            background-size: cover;
            background-image: url(./../../images/about5.png);
          }
        }

        .item_2 {
          padding-top: 37px;
          padding-left: 40px;
          background: $color-white-1;
          box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
          @include textLine(10%);

          .text_line {
            margin-bottom: 10px;
            margin-left: 15px;
          }

          .text {
            padding-bottom: 20px;

            p {
              line-height: 35px;
              text-align: left;
              padding-bottom: 10px;
            }
          }

          .img {
            img {
              //margin-left: 28px;
              position: relative;
              bottom: -60px;
            }
          }
        }

        .item_3 {
          margin-top: 96px;
          padding-top: 41px;
          padding-left: 40px;
          background: $color-white-1;
          box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);

          .text {
            padding-right: 40px;
            padding-bottom: 20px;
            padding-left: 30px;
            @include textLine(0);

            p {
              line-height: 30px;
              text-align: left;
              margin-top: 10px;
            }
          }

          .img {
            img {
              position: relative;
              bottom: -60px;
              margin-top: -60px;
            }
          }
        }

        .item_4 {
          margin-top: 96px;
          padding-top: 41px;
          padding-left: 40px;
          background: $color-white-1;
          box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);

          .text {
            padding-bottom: 20px;
            padding-right: 40px;
            @include textLine(0);

            p {
              line-height: 30px;
              text-align: left;
              margin-top: 10px;
            }
          }

          .img {
            img {
              position: relative;
              bottom: -60px;
              margin-top: -60px;
              padding-right: 10px;
            }
          }
        }

        .item_5 {
          padding-top: 33px;
          padding-bottom: 52px;
          padding-left: 40px;
          padding-right: 60px;
          margin-top: 86px;
          background: $color-white-1;
          box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
          @include textLine(0);

          p {
            line-height: 35px;
            padding-top: 10px;
          }
        }

        .family {
          margin-top: 38px;

          .img {
            img {
              width: 100%;
            }

            background: $color-white-1;
            box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
            text-align: center;

            p {
              padding: 22px 0;
            }

            &--custom {
              p {
                padding: 10px 28px;
              }
            }
          }

          .text {
            padding-left: 47px;
            padding-top: 5px;

            p {
              line-height: 30px;
              padding-bottom: 20px;

              span {
                color: #636363;
                font-size: 35px;
              }
            }

            &--custom {
              padding-left: 0;

              p {
                padding-left: 0;
                padding-right: 47px;
                text-align: left;
                padding-bottom: 0;
                margin-top: 6px;
              }
            }
          }
        }

        .des {
          line-height: 35px;
          margin-top: 34px;
          text-align: left;
          margin-bottom: 20px;

          &--custom {
            margin-top: 0px;
            margin-bottom: 32px;
          }
        }
      }
    }
  }

  .button_list {
    margin-top: 20px;
    white-space: nowrap;
    overflow-x: auto;
    padding-left: 10px;

    a {
      width: 277px;
      display: inline-block;
      height: 60px;
      padding-top: 16px;
      padding-bottom: 18px;
      padding-left: 40px;

      font-size: 20px;
      color: #37677E;
    }

    .item_1,
    .item_2,
    .item_3 {
      background-color: #b1e4e8;
      color: #37677e;
      font-size: 20px;
      margin-right: 8px;

      p {
        text-align: left;
        margin-left: 60px;
      }
    }

    .item_1 {
      position: relative;

      &::before {
        position: absolute;
        content: "";
        background-image: url(./../../icons/about1.svg);
        width: 33px;
        height: 37px;
        display: inline-block;
        top: 10px;
        left: 10px;
      }
    }

    .item_2 {
      position: relative;

      &::before {
        position: absolute;
        content: "";
        background-image: url(./../../icons/aboutus3.svg);
        width: 36px;
        height: 40px;
        display: inline-block;
        top: 10px;
        left: 10px;
      }
    }

    .item_3 {
      position: relative;

      &::before {
        position: absolute;
        content: "";
        background-image: url(./../../icons/aboutus2.svg);
        width: 36px;
        height: 40px;
        display: inline-block;
        top: 10px;
        left: 10px;
      }
    }
  }

  .button_list::-webkit-scrollbar {
    display: none;
  }

  @include breakpoint3 {
    .about_video {
      margin: 0px;
      padding-top: 20px;
      img {
        margin-top: 30px;
        padding: 0 10px;
      }
    }
    .cover-page {
      &__title {
        margin-top: -162px;

      }
    }
    .collum {
      &::after {
        content: unset;
      }

      &__right {
        .about {
          &__left {
            padding-right: 0;

            &__text {
              text-align: left;
              padding-top: 20px;
              @include textLine(0);
              padding-right: 10px;
            }
          }

          &__right {
            img {
              width: 100%;
              margin-top: 30px;
            }
          }
        }

        .about_below {
          &__left {
            padding-right: 10px;
            padding-top: 30px;
            @include textLine(0);

            &__content {
              margin-top: 10px;
              text-align: left;
            }

            .descreption {
              margin-top: 20px;
              margin-left: 25px;
            }
          }

          &__right {
            padding-top: 20px;
            // @include text_line(100%);
            &__body {
              padding-top: 10px;
              padding-right: 10px;

            }

            .list {
              padding-top: 8px;

              p {
                font-size: 14px;
              }
            }
          }
        }

        .ourside {
          padding-left: 10px;
          padding-top: 20px;
          padding-bottom: 0;
          @include textLine(0);

          &__body {
            line-height: 35px;
            margin-top: 5px;
            padding-right: 10px;
            padding-right: 0;
          }
        }
      }
    }

    .choose {
      background-color: unset;

      .content1 {
        &__right {
          padding-top: 30px;
          padding-left: 0;

          .item_1 {
            margin-bottom: 30px;

            .text {
              p {
                line-height: 35px;
                text-align: left;
                font-size: 16px;
                padding-top: 10px;
                padding-right: 10px;
                padding-left: 10px;
                padding-bottom: 231px;

                span {
                  font-weight: 400;
                  font-size: 20px;
                  color: #636363;
                }
              }

              background-position: center;
              background-size: cover;
              background-image: url(./../../images/about14.png);
            }

            .img {
              img {
                width: 100%;
                padding: 0 10px;
              }
            }

            padding-bottom: 0px;
          }

          .item_2 {
            padding-top: 0px;
            margin-left: 0px;
            padding-left: 0;
            background: $color-white-1;
            box-shadow: unset;
            @include textLine(0);

            .text_line {
              margin-bottom: 10px;
              padding-left: 0;
              margin-left: 0;
              margin-top: 15px;

              &--custom {
                display: none !important;
              }
            }

            .text {
              p {
                line-height: 35px;
                text-align: left;
              }
            }

            .img {
              img {
                margin-left: 0;
                position: relative;
                bottom: 0;
                width: 100%;
              }
            }
          }

          .item_3 {
            margin-top: -20px;
            padding-top: 0;
            padding-left: 0;
            background: $color-white-1;
            box-shadow: unset;

            .text {
              margin-top: 15px;
              padding-right: 10px;
              padding-left: 10px;
              @include textLine(0);

              p {
                line-height: 30px;
                text-align: left;
                margin-top: 10px;
              }
            }

            .img {
              img {
                position: relative;
                bottom: 0px;
                margin-top: 19px;
                width: 100%;
              }
            }
          }

          .item_4 {
            margin-top: 0;
            padding-top: 0px;
            padding-left: 0;
            background: $color-white-1;
            box-shadow: unset;

            .text {
              padding-right: 10px;
              padding-left: 10px;
              @include textLine(0);
              margin-top: 10px;

              p {
                line-height: 30px;
                text-align: left;
                margin-top: 10px;

              }
            }

            .img {
              img {
                position: relative;
                bottom: 0;
                margin-top: 20px;
                padding-right: 0px;
                width: 100%;
              }
            }
          }

          .item_5 {
            padding-top: 0;
            padding-bottom: 0;
            padding-left: 10px;
            padding-right: 1px;
            margin-top: 0px;
            background: $color-white-1;
            box-shadow: unset;
            @include textLine(0);

            p {
              line-height: 35px;
              padding-top: 10px;
            }
          }

          .family {
            &--custom {
              margin-top: -6px;
            }

            margin-top: 35px;

            .img {
              margin: 0 10px;
              padding: 0;

              img {
                width: 100%;
              }

              background: $color-white-1;
              box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
              text-align: center;

              p {
                padding: 15px 0;
              }

              &--custom {
                p {
                  padding: 25px 15px;
                }
              }
            }

            .text {
              padding-left: 10px;
              padding-top: 25px;

              p {
                line-height: 30px;
                padding-bottom: 10px;

                span {
                  color: #636363;
                  font-size: 35px;
                }
              }

              &--custom {
                padding-left: 0;

                p {
                  padding-left: 0;
                  padding-left: 10px;
                  padding-right: 10px;
                  text-align: left;
                }
              }
            }
          }

          .des {
            line-height: 35px;
            margin-top: 0;
            padding: 11px 10px;
            text-align: left;

            &--custom {
              margin-top: -20px;
              margin-bottom: 0;
              border-bottom: 7px solid #F5F5F5;
              padding-bottom: 20px;
            }
          }
        }
      }
    }
  }
}
