@mixin font-face1($font-name,$folder-name, $eot,$woff2,$woff,$tff,$svg, $font-weight, $font-style) {
  @font-face {
    font-family: $font-name;
    src: url(./../../font/#{$folder-name}/#{$eot}), /* IE9+ Compat Modes */
    url(./../../font/#{$folder-name}/#{$woff2}) format('woff2'), /* Super modern browsers */
    url(./../../font/#{$folder-name}/#{$woff}) format('woff'), /* Modern browsers */
    url(./../../font/#{$folder-name}/#{$tff})  format('truetype'), /* Safari, Android, iOS */
    url(./../../font/#{$folder-name}/#{$svg}) format('svg'); /* Legacy iOS */
    font-weight: $font-weight;
    font-style: $font-style;
  }
}
// media screen
@mixin breakpoint1 {
  @media screen and (max-width: #{$breakpoint1}) {
    @content;
  }
}

@mixin breakpoint2 {
  @media screen and (max-width: #{$breakpoint2}) {
    @content;
  }
}
@mixin breakpoint3 {
  @media screen and (max-width: #{$breakpoint3}) {
    @content;
  }
}

@mixin breakpoint4 {
  @media screen and (min-width: #{$breakpoint3}) {
    @content;
  }
}

@mixin breakpoint5 {
  @media screen and (max-width: #{$breakpoint4}) {
    @content;
  }
}

//cover
@mixin cover($height, $nameOfPicture, $nameOfClass, $margin_of_title) {
  .cover {
    position: relative;
    &__img {
      width: 100%;
      object-fit: cover;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      height: $height;
    }
    &--#{$nameOfClass} {
      .cover__img {
        img {
          width: 100%;
          height: 300px;
        }
        width: 100%;
        height: $height;
        background-image: url(./../../images/#{$nameOfPicture});
      }
    }
    &__content {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;

      &__title {
        margin-top: $margin_of_title;
        color: $color-white-1;
        text-align: center;
      }
    }
  }
}
@mixin text_line($width) {
  .text_line {
    font-size: 25px;
    color: $color-blue-3;
    position: relative;
    text-transform: uppercase;
    text-align: left;
    line-height: 40px;
    z-index: 99;
    &::after {
      content: "";
      width: $width;
      height: 17px;
      background: rgba(215, 244, 247, 0.5);
      display: inline-block;
      position: absolute;
      top: 20px;
      z-index: -1;
      left: -10px;
    }
  }
}
@mixin textLine($ml) {
  .text_line {
    font-size: 25px;
    color: $color-blue-3;
    position: relative;
    text-transform: uppercase;
    text-align: left;
    display: inline-block;
    line-height: 40px;
    margin-right: $ml;
    z-index: 99;
    //font-weight: 700;
    &::after {
      content: "";
      width: 110%;
      height: 17px;
      background: rgba(177, 228, 232, 0.5);
      display: block;
      position: absolute;
      top: 18px;
      z-index: -1;
      left: -10px;
    }
    @include breakpoint3 {
      display: block;
      margin-right: 0;
      font-size: 25px;
      color: $color-blue-3;
      position: relative;
      text-transform: uppercase;
      text-align: left;
      line-height: 40px;
      z-index: 99;
      &::after {
        content: "";
        width: 100%;
        height: 17px;
        background: rgba(215, 244, 247, 0.5);
        display: inline-block;
        position: absolute;
        top: 20px;
        z-index: -1;
        left: -10px;
      }
  }
  }

}

@mixin playbutton($wd,$pt) {
  .round-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
    display: block;
    width: $wd;
    height: $wd;
    padding-top: $pt;
    padding-left: 8px;
    line-height: 20px;
    border: 3px solid #fff;
    border-radius: 50%;
    color: #f5f5f5;
    text-align: center;
    text-decoration: none;
    font-size: 20px;
    font-weight: bold;
    transition: all 0.3s ease;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);

    &:hover {
      background-color: rgba(0, 0, 0, 0.8);
      box-shadow: 0px 0px 10px rgba(255, 255, 100, 1);
      text-shadow: 0px 0px 10px rgba(255, 255, 100, 1);
    }
  }
}