.button {
  outline: 0;
  padding: 6px 4px;
   
  font-size: 16px;
  text-align: center;
  transition: box-shadow 0.35s;
  -webkit-transition: box-shadow 0.35s;
  -moz-transition: box-shadow 0.35s;
  -ms-transition: box-shadow 0.35s;
  -o-transition: box-shadow 0.35s;
  background-color: darkgray;
  color: $color-white-1;
  &:focus {
    outline: 0;
  }

  &:disabled {
    cursor: not-allowed;
  }

  &:hover {
    text-decoration: none;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.14);
  }

  &--dark {
    background-color: darkgray;
  }

  &--blue {
    background-color: $color-blue-3;
    color: $color-blue-1;
  }

  &--number {
    background-color: #b1e4e8;
  }

}

/* Rectangle 75 */
