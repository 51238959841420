.map {
  background-color: white;
  padding: 12px 0;

  &__left {
    padding-top: 54px;

    h2 {
      font-family: "Zapfino", sans-serif;
      color: $color-blue-3;
      text-align: center;
      font-size: 100px;
      padding-bottom: 50px;
      position: relative;

      &::after {
        content: "";
        width: 211px;
        height: 1px;
        background-color: #37677E;
        position: absolute;
        bottom: 88px;
        right: 119px;

      }
    }

    &__content {
      padding-bottom: 23px;
      display: flex;

      ul {
        li {
          margin-bottom: 13px;

          p {
            padding-left: 19px;
            padding-right: 57px;
            padding-top: 12px;

            color: $color-blue-3;
          }

          height: 46px;
        }
      }

      .left {
        margin-right: 30px;
      }

      .right {
        .custom {
          background-color: $color-blue-3;

          p {
            color: $color-white-1;
          }
        }
      }
    }
  }

  &__right {
    iframe {
      height: 573px;
      margin-top: 44px;
    }

    .popup {
      width: 308px;
      height: 119px;
      background-color: $color-white-1;
      box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);
      position: absolute;
      top: 160px;
      left: 83px;
      padding-left: 31px;
      padding-top: 13px;

      a {
        font-size: 18px;
        text-decoration-line: underline;
        color: $color-blue-3;

      }

      p {
        margin-bottom: 10px;
      }
    }
  }
}

footer {
  .current-menu-item {
    background-color: unset !important;
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      // IE10+
      background-color: transparent;
    }
  }

  border-top: 6px solid $color-blue-1;

  .top {
    &__content {
      padding-top: 104px;
      padding-left: 0;
      padding-right: 0;
    }

    background-color: $color-black-1;

    &__left {
      &__title {
        font-weight: 400;
        font-size: 23px;
        line-height: 28px;
        align-items: center;
        color: $color-blue-1;

        &::after {
          margin-top: 18px;
          content: "";
          display: block;
          width: 76%;
          height: 1px;
          background: $color-blue-1;
        }
      }

      &__name {
        margin-top: 15px;
        line-height: 40px;
        color: $color-white-1;
        font-weight: normal;
        font-size: 33px;
      }

      &__des {
        color: $color-white-1;
        font-size: 23px;

      }

      img {
        width: 152px;
        height: 152px;
        margin-top: 16px;
      }

      &__address {

        font-size: 16px;
        color: $color-white-1;
        margin-top: 14px;
      }

    }

    &__middle {
      padding-left: 30px;

      &__title {
        font-family: "Pacifico", sans-serif;
        font-weight: normal;
        font-size: 37px;
        line-height: 28px;
        color: $color-blue-1;
        margin-bottom: 32px;

        &::after {
          margin-top: 20px;
          content: "";
          display: block;
          width: 100%;
          height: 1px;
          background: $color-blue-1;
        }
      }

      &__content {
        margin-bottom: 48px;

        &__title {

          font-size: 16px;
          line-height: 26px;
          color: $color-blue-1;
        }

        .textwidget {
          p {
            font-style: normal;

            font-size: 16px;
            color: $color-white-1;

            a {
              font-style: normal;

              font-size: 16px;
              color: $color-white-1;
            }

          }
        }

        &__item {
          .list-socials {
            color: white;
          }

          display: flex;
          margin-top: 20px;

          .logo {
            color: $color-white-1;
            margin-right: 46px;

            i {
              font-size: 23px;
            }

            p {
              color: $color-white-1;
            }
          }
        }

      }
    }

    &__right {
      padding-left: 62px;

      &__title {
        margin-left: 0;
        font-family: "Pacifico", sans-serif;
        font-weight: normal;
        font-size: 37px;
        line-height: 28px;
        color: $color-blue-1;
        margin-bottom: 25px;

        &::after {
          margin-top: 20px;
          content: "";
          display: block;
          width: 100%;
          height: 1px;
          background: $color-blue-1;
        }
      }

      .button--footer {
        margin-left: 0;
        background-color: $color-blue-4;
        padding: 16px 20px;
        display: inline-block;
        margin-top: 13px;
      }

      ul {
        text-align: left;

        li {
          a {
            color: $color-white-1;
            line-height: 40px;
            font-size: 16px;

          }
        }

        li:nth-child(odd) {
          padding-left: 44px;

        }
      }
    }
  }

  .copyright {
    background-color: $color-black-2;
    //height: 63px;
    padding: 18px 0;

    .item {
      color: $color-white-1;
      display: flex;

      .copyr {
        color: $color-white-1;

        margin-left: auto
      }
    }

  }

  // Responsive
  @include breakpoint3 {
    .top {
      &__content {
        padding-top: 34px;
        padding-bottom: 37px;
      }

      &__left {
        padding-left: 15px;
      }

      &__middle {
        margin-top: 45px;
        padding-left: 15px;

        &__title {
          margin-bottom: 21px;
        }

        &__content {
          margin-bottom: 18px;
        }
      }

      &__right {
        margin-top: 47px;
        padding-left: 15px;

        &__title {
          padding-left: 0;
          margin-left: 0;
          margin-bottom: 37px;
        }

        .button--footer {
          margin-left: 0;
          margin-top: 0;
        }

        ul {
          text-align: left;
          margin-top: 20px;

          li {
            a {
              color: $color-white-1;
              line-height: 40px;
              font-size: 16px;

            }
          }

          li:nth-child(odd) {
            padding-left: 0;

          }
        }

      }
    }
    .copyright {
      padding: 18px 0;

      .item {
        display: block;

        p {
          text-align: center;
        }

        .copyr {
          margin-left: unset;
          display: block;
          text-align: center;
        }
      }

    }
  }

}

.list-socials {
  margin-left: -10px;
  display: flex;
  position: relative;
  z-index: 99;

  li {
    display: inline-block;
    margin: 0 13px;
  }

  a {
    color: #fff;
    display: inline-grid;
  }

  i.fa {
    font-size: 27px;
  }
}

@include breakpoint3 {
  .map {
    padding: 0;

    .container {
      &--custom {
        padding-left: 0;
        padding-right: 0;
      }
    }

    &__left {
      padding-left: 15px;
      padding-right: 15px;
      padding-top: 0;

      h2 {
        font-size: 80px;
        padding-bottom: 46px;
        padding-top: 40px;

        &::after {
          content: unset;
        }
      }

      &__content {
        padding-bottom: 23px;
        display: flex;

        ul {
          li {
            margin-bottom: 13px;

            p {
              padding-left: 0;
              padding-right: 0;
              padding-top: 12px;
            }
          }
        }

        .left {
          margin-right: 0px;
        }

        .right {

          margin-left: auto;

          p {
            padding-right: 57px;
            padding-left: 19px;
          }
        }
      }
    }

    &__right {
      margin-bottom: -31px;
      padding: 0;

      iframe {
        height: 573px;
        margin-top: 0;
      }

      .popup {
        width: 308px;
        height: 119px;
        background-color: $color-white-1;
        box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);
        position: absolute;
        top: 104px;
        left: 53px;
        padding-left: 31px;
        padding-top: 13px;

        a {
          font-size: 18px;
          text-decoration-line: underline;
          color: $color-blue-3;

        }

        p {
          margin-bottom: 10px;
        }
      }
    }
  }
}

@media screen and (max-width: 367px) {
  .map {
    &__right {
      .popup {
        left: 5px;
      }
    }
  }
  .list-socials {
    display: table-column-group;

    li {
      margin: 0 13px 13px 13px;
    }
  }
}