.for-adults-ph {
    .cover-page{
        margin-bottom: 0;
        &__title{
            line-height: 40px;
            padding-top: 69px;
        }
    }
    text-align: left;
    .ort {
        .content_1 {
            padding-bottom: 49px;
            padding-top: 74px;
            &__left {
                display: flex;
                align-items: flex-end;
                .item {
                    padding-top: 24px;
                    padding-bottom: 48px;
                    @include textLine(0);
                    p {
                        padding-top: 20px;
                        line-height: 35px;
                    }
                    padding-left: 47px;
                    padding-right: 60px;
                }
            }
            &__right {
                img {
                    width: 94%;
                    height: auto;
                    margin-top: -210px;
                }
            }
        }
    }
    .comon {
        position: relative;
        .background {
            width: 100%;
            height: 828px;
            position: absolute;
            top: 196px;
            left: 0;
            right: 0;
            background: rgba(232, 254, 253, 0.5);
        }
        .content_1 {
            &--dark{
                background-color: #f6f6f6;
                padding-top: 50px;
            }
            padding-bottom: 49px;
            padding-top: 74px;
            &__left {
                display: flex;
                align-items: center;
                .item {
                    padding-bottom: 48px;
                    @include textLine(0);
                    p {
                        padding-top: 20px;
                        line-height: 35px;
                    }
                    &--custom{
                        @include textLine(0);
                    }
                }
            }
            &__right {
                img {
                    width: 80%;
                    height: auto;
                }
            }
        }
        .content_2 {
            padding: 45px;
            &__left {
                img {
                    width: 100%;
                    height: 569px;
                }
            }
            &__right {
                display: flex;
                align-items: center;
                .item {
                    @include textLine(0);
                    p {
                        padding-top: 20px;
                        line-height: 35px;
                    }
                    padding-right: 60px;
                }
            }
        }
    }
    .diffirent {
        .content_1 {
            padding-bottom: 49px;
            padding-top: 74px;
            &__left {
                display: flex;
                align-items: center;
                .item {
                    padding-bottom: 48px;
                    @include textLine(0);
                    p {
                        padding-top: 20px;
                        line-height: 35px;
                    }
                }
            }
            &__right {
                img {
                    float: right;
                    width: 78%;
                    margin-bottom: 20px;
                }
            }
        }
    }
    @include breakpoint3 {
        .cover-page{
            &__title{
                margin-top: -74px;
            }
        }
        .ort {
            .content_1 {
                padding: 0;
                &__left {
                    display: flex;
                    align-items: flex-end;
                    .item {
                        padding-bottom: 20px;
                        padding-top: 24px;
                        p {
                            padding-top: 10px;
                            line-height: 35px;
                        }
                        padding-left: 0;
                        padding-right: 0;
                    }
                }
                &__right {
                    img {
                        width: 100%;
                        height: auto;
                        margin-top: -90px;
                    }
                }
            }
        }
        .comon {
            .content_1 {
                &--dark{
                    background-color: unset;
                    padding-top: 0;
                    margin-top: -20px;
                }
                padding: 0;
                &__left {
                    display: flex;
                    align-items: center;
                    .item {
                        padding-top: 24px;
                        padding-bottom: 20px;
                        p {
                            padding-top: 10px;
                            line-height: 35px;
                        }
                    }
                }
                &__right {
                    img {
                        width: 100%;
                        height: auto;
                    }
                }
            }
            .content_2 {
                padding: 0;
                margin-bottom: 15px;
                &__left {
                    img {
                        width: 100%;
                        height: auto;
                    }
                }
                &__right {
                    display: flex;
                    align-items: center;
                    .item {
                     
                        padding-top: 24px;
                        p {
                            padding-top: 10px;
                            line-height: 35px;
                        }
                        padding-right: 0;
                    }
                }
            }
        }
        .diffirent {
            .content_1 {
                padding: 0;
                &__left {
                    display: flex;
                    align-items: center;
                    .item {
                        padding-bottom: 48px;
                        p {
                            padding-top: 10px;
                            line-height: 35px;
                        }
                    }
                }
                &__right {
                    img {
                        float: right;
                        width: 100%;
                        margin-bottom: 20px;
                    }
                }
            }
        }
        .map{
            border-top: 10px solid #fafafa;
        }
    }

}
