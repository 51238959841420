header {
  height: 86px;
  background-color: $color-black-1;
  position: relative;
  z-index: 999;
  .menu-btn,
  .search-btn {
    display: none;
  }
  .title {
    a {
      position: relative;
      top: 45%;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      align-items: center;
      color: $color-white-1;
    }
  }
  .logo {
    display: inline-block;
    margin-top: 9px;
  }

  .right_menu {
    justify-content: flex-end;
    margin-top: 32px;
    display: flex;
    color: $color-white-1;

    &__phone {
      a {
        color: $color-white-1;

        &::before {
          content: "";
          background-image: url(./../../icons/phone.svg);
          width: 15.07px;
          height: 16.86px;
          display: inline-block;
          margin-right: 8px;
          margin-bottom: -3px;
          background-position: center;
          background-repeat: no-repeat;
        }
      }
    }
    &__search {
      cursor: pointer;
      margin-left: 32px;
      margin-top: -4px;
      &::before {
        content: "";
        width: 15px;
        height: 15px;
        display: inline-block;
        background-color: #5f5f5f;
        padding: 16px;
        background-repeat: no-repeat;
        border-radius: 20px;
        background-position: center;
        background-image: url(./../../icons/search1.svg);

      }
    }
    &__humbuger {
      margin-left: 26px;

    }
  }
  //Resposive
  @include breakpoint3 {
    height: 63px;
    .logo {
      margin-top: 0;
      padding-left: 0;

      img {
        width: 135px;
        height: 43px;
        margin-top: 9px;
        margin-left: -5px;
        float: left;
      }
    }
    .menu,
    .search {
      top: 63px;
    }
    .right_menu {
      margin-top: 21px;
    }
  }
  form {
    display: flex;
    justify-content: center;
  }
  .search-box,
  .close-icon,
  .search-wrapper {
    position: relative;
    padding: 10px 0 10px 22px
  }
  .search-wrapper {
    width: 500px;
    margin: auto;
    margin-top: 50px;
  }
  .search-box {
    width: 80%;
    outline: 0;
    background-color: #f1f1f1;
    margin: 29px 0;
  }
  .close-icon {
    border: 1px solid transparent;
    background-color: transparent;
    display: inline-block;
    vertical-align: middle;
    outline: 0;
    cursor: pointer;
  }
  .close-icon:after {
    content: "";
    background-image: url(./../../icons/clear.svg);
    display: block;
    width: 21px;
    height: 21px;
    position: absolute;
    z-index: 1;
    right: 35px;
    top: 0;
    bottom: 0;
    margin: auto;
    padding: 2px;
    text-align: center;
    cursor: pointer;
  }
  .search-box:not(:valid) ~ .close-icon {
    display: none;
  }
}
.search {
  list-style: none;
  position: absolute;
  width: 100%;
  height: auto;
  top: 86px;
  left: 0;
  padding: 0 0 10px 0;
  clear: both;
  background: $color-white-1;
  transition: 0.3192s cubic-bezier(0.04, 0.04, 0.12, 0.96) 0.1008s;
  transform: scale(1, 0);
  transform-origin: top;
}
.search.search-btn-display {
  transform: scale(1, 1);
  transform-origin: top;
  transition: 0.3192s cubic-bezier(0.04, 0.04, 0.12, 0.96) 0.1008s;
  opacity: 1;
}

