.type-of-braces-ph {
.cover-page{
  margin-bottom: 0;
}
  .type_row {
    .content {
      position: relative;
      padding: 61px 0px;
      &--dark {
        background-color: $color-dark-4;
        &::before {
          content: "";
          width: 670px;
          height: 459px;
          top: 0;
          left: 80px;
          position: absolute;
          background-color: $color-blue-10;
        }
      }
      .bacground {
        width: 100%;
        height: 396px;
        position: absolute;
        background-color: $color-dark-4;
        z-index: -3;
        bottom: 0;
      }
      &__img {
        img {
          width: 82%;
          height: 614px;
          margin-left: 42px;
        }
        &--custom {
          img {
            float: right;
          }
        }
      }
      &__text {
        &:before {
          top: 70px;
        }

        &:after {
          bottom: 70px;
        }
        @include text_line(88%);
        display: flex;
        align-items: center;
        position: relative;
        .item {
          p {
            line-height: 35px;
            margin-top: 20px;
            text-align: left;
          }
          padding: 0 40px;
          &--1line {
            &:before {
              content: "";
              height: 44%;
              width: 1px;
              background-color: #37677e;
              position: absolute;
              top: 55%;
              transform: translateY(-50%);
              left: 12px;
            }
          }
          &--1line-custom {
            &:before {
              content: "";
              height: 64%;
              width: 1px;
              background-color: #37677e;
              position: absolute;
              top: 54%;
              transform: translateY(-50%);
              left: 10px;
            }
          }
          &--2line {
            &:before,
            &:after {
              content: "";
              height: 94px;
              width: 1px;
              background-color: $color-blue-3;
              position: absolute;
              left: 50%;
              transform: translateX(-50%);
              -webkit-transform: translateX(-50%);
              -moz-transform: translateX(-50%);
              -ms-transform: translateX(-50%);
              -o-transform: translateX(-50%);
            }
            &:before {
              top: 0px;
            }
            &:after {
              bottom: 0px;
            }
          }
        }
      }
    }
  }
  @include breakpoint3 {
    .type_row {
      .content {
        position: relative;
        padding: 0px;
        &--dark {
          background-color: unset;
          &::before{
            content: unset;
          }
        }
        &__img {
          margin-top: 20px;
          .img_mobile {
            margin-top: -59px;
          }
          img {
            width: 100%;
            height: auto;
            margin-left: 0px;
          }
          &--custom {
            img {
              float: unset;
            }
          }
        }
        &__text {
          @include text_line(100%);
          display: flex;
          align-items: center;
          position: relative;
          .item {
            margin-top: 20px;
            p {
              line-height: 35px;
              margin-top: 10px;
              text-align: left;
            }
            padding: 0px;
            &--1line,
            &--1line-custom {
              &:before {
                content: unset;
              }
            }
            &--2line {
              &:before,
              &:after {
                content: unset;
              }
            }
          }
        }
      }
    }
  }
}
