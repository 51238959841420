/* Hamburger menu button */
.open{
  display: block !important;
}
.none{
 height: auto;
}
.hamburger {
  width: 36px;
  height: 17px;
  position: relative;
  margin-left: 26px;
  // margin: 50px auto;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
  -ms-transition: 0.5s ease-in-out;
  span {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: $color-white-1;
    border-radius: 9px;
    opacity: 1;
    right: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
    &:nth-child(1) {
      top: 0px;
      width: 100%;
    }
    &:nth-child(2) {
      margin-left: auto;
      top: 9px;
      width: 80%;
    }
    &:nth-child(3) {
      width: 50%;
      top: 17px;
    }
  }
}
.hamburger.open span:nth-child(1) {
  width: 36px;
  top: 10px;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
}
.hamburger.open span:nth-child(2) {
  opacity: 0;
  left: -60px;
}
.hamburger.open span:nth-child(3) {
  width: 36px;
  top: 10px;
  -webkit-transform: rotate(-135deg);
  -moz-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  transform: rotate(-135deg);
}
/* Hamburger menbu text */
.menu {
  list-style: none;
  position: absolute;
  width: 100%;
  height: auto;
  top: 86px;
  left: 0;
  padding: 0 0 10px 0;
  clear: both;
  background: $color-white-1;
  transition: 0.3192s cubic-bezier(0.04, 0.04, 0.12, 0.96) 0.1008s;
  transform: scale(1, 0);
  transform-origin: top;
  box-shadow: 0 3px 2px -2px #666;
  padding: 15px 0;
  a{
    font-size: 16px;
    line-height: 44px;
    height: 44px;
    display: block;
    text-align: center;
    align-items: center;
    letter-spacing: 0.2em;
    //text-transform: uppercase;
    opacity: 0;
    transition: 0.5s;
    color: #000;
    position: relative;
    padding: 0 40px;
  }
  li {
    display: block;
    width: 390px;
    max-width: 100%;
    margin: 0 auto;
    text-align: center;
    transition: 0.5s;
    margin-top: 7px;
    &:hover{
      > a{

        background-color: $color-blue-1;
      }
    }
  }
  .sub-menu{
    display: none;
    background-color: #FAFAFA;
  }
  .menu-item-has-children{
    > a{
      &:after{
        font-family: FontAwesome;
        content: '\f0da';
        position: absolute;
        right: 30px;
      }
      //&:hover{
      //  transition: all .4s;
      //  //.sub-menu{
      //  //  display: block;
      //  //}
      //  &:after{
      //    content: '\f0d7';
      //  }
      //}
    }
  }
  .active {
    > a {
      &:after {
        font-family: FontAwesome;
        content: '\f0d7';
        position: absolute;
        right: 30px;
      }
    }
  }
}
.menu.open {
  transform: scale(1, 1);
  transform-origin: top;
  transition: 0.3192s cubic-bezier(0.04, 0.04, 0.12, 0.96) 0.1008s;
  a {
    opacity: 1;
    white-space: nowrap;
    transition: 0.3192s cubic-bezier(0.04, 0.04, 0.12, 0.96) 0.2s;
  }
}
.current-menu-parent , .current-menu-item{
  background-color: #b1e4e8;
}
//mobile
.menu_mobile{
  margin: 30px 0px 10px 0px ;
  border-top: 10px solid #eee;
  padding: 30px 0 0px 0;
  .button--cover{
    margin: auto  15px;
    padding: 18px 10px;
    font-size: 16px;
    line-height: 24px;
    height: auto;
    background-color: $color-blue-3;
    color: $color-blue-1;
  }
  .phone{
    padding: 20px 0;
    a{
      color:$color-blue-3 !important;
      font-size: 20px;
    }
  }
}
