.for-children-ph {
  .cover-page{
    margin-bottom: 0;
  }
  .child_content {
    text-align: left;
    .content_1 {
      &--yellow{
        background-color: $color-dark-4;
      }
      padding: 45px;
      &__left {
        display: flex;
        align-items: center;
        .item {
         @include textLine(0);
          p {
            padding-top: 20px;
            line-height: 35px;
          }
          padding-left: 47px;
          padding-right: 60px;
        }
      }
      &__right {
        img {
          margin-left: 101px;
          height: 547px;
        }
      }
      &--1 {
        background: red;
      }
    }
    .content_2 {
      padding: 45px;
      &__left {
        img {
          width: 76%;
          margin-left: 47px;
          height: 608px;
        }
      }
      &__right {
        display: flex;
        align-items: center;
        .item {
         @include textLine(0);
          p {
            padding-top: 20px;
            line-height: 35px;
          }
          padding-left: 47px;
          padding-right: 60px;
        }
      }
    }
  }
  @include breakpoint3{
    
    .child_content {
      border-bottom: 10px solid #f5f5f5;

      .content_1 {
        &--yellow{
          background-color: $color-yellow-1;
        }
        padding: 25px 0;
        &__left {
          display: flex;
          align-items: center;
          .item {
            padding-top: 24px;
            @include textLine(0);
            p {
              padding-top: 10px;
              line-height: 35px;
            }
            padding-left: 0;
            padding-right: 0;
          }
        }
        &__right {
          img {
            margin-left: 0;
            width: 100%;
            height: auto;
          }
        }
        &--1 {
          background: red;
        }
      }
      .content_2 {
        padding:  0;
        &__left {
          img {
            width: 100%;
            margin-left: 0;
            height: 100%;
          }
        }
        &__right {
          display: flex;
          align-items: center;
          .item {
            padding-top: 24px;
            @include textLine(0);
            p {
              padding-top: 10px;
              line-height: 35px;
            }
            padding-left: 0;
            padding-right: 0;
          }
        }
      }
    }
  }
}
