//font format
@include font-face1(Gotham, "Gotham-book", "Gotham-Book.eot", "Gotham-Book.woff2", "Gotham-Book.woff", "Gotham-Book.ttf", "Gotham-Book.svg", normal, normal);
//@include font-face1(Gotham,"Gotham-medium","iCielGotham-Medium.eot" ,"iCielGotham-Medium.woff2","iCielGotham-Medium.woff","GothamMediumRegular.ttf","iCielGotham-Medium.svg" ,500, normal);
@include font-face1(Gotham, "Gotham-thin", "iCielGotham-Thin.eot", "iCielGotham-Thin.woff2", "iCielGotham-Thin.woff", "iCielGotham-Thin.ttf", "iCielGotham-Thin.svg", 100, normal);
@include font-face1(Gotham, "Gotham-unltra", "iCielGotham-Ultra.eot", "iCielGotham-Ultra.woff2", "iCielGotham-Ultra.woff", "iCielGotham-Ultra.ttf", "iCielGotham-Ultra.svg", 700, normal);
@include font-face1(Amada, "Amanda", "AmandaSignatureRegular.eot", "AmandaSignatureRegular.woff2", "AmandaSignatureRegular.woff", "AmandaSignatureRegular.ttf", "AmandaSignatureRegular.svg", normal, normal);
@include font-face1(Pacifico, "Pacifico", "Pacifico-Regular.eot", "Pacifico-Regular.woff2", "Pacifico-Regular.woff", "Pacifico-Regular.ttf", "Pacifico-Regular.svg", normal, normal);
@include font-face1(Zapfino, "Zaptifico", "ZapfinoExtraLT-One.eot", "ZapfinoExtraLT-One.woff2", "ZapfinoExtraLT-One.woff", "ZapfinoExtraLT-One.ttf", "ZapfinoExtraLT-One.svg", normal, normal);
//@font-face {
//  font-family: Gotham;
//  src: url(../../font/Gotham-medium/iCielGotham-Medium.eot),
//  url(../../font/Gotham-medium/iCielGotham-Medium.svg) format('svg'),
//  //url(../../font/Gotham-medium/GothamMediumRegular.ttf) format('truetype');
//url(../../font/Gotham-Medium.ttf) format('truetype');
//  font-weight: 500;
//  font-style: normal;
//}
@font-face {
  font-family: "Gotham";
  font-weight: 500;
  src: url("//db.onlinewebfonts.com/t/3a47f5f06b5484abfeee9eac90348a9c.eot");
  src: url("//db.onlinewebfonts.com/t/3a47f5f06b5484abfeee9eac90348a9c.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/3a47f5f06b5484abfeee9eac90348a9c.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/3a47f5f06b5484abfeee9eac90348a9c.woff") format("woff"), url("//db.onlinewebfonts.com/t/3a47f5f06b5484abfeee9eac90348a9c.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/3a47f5f06b5484abfeee9eac90348a9c.svg#Gotham") format("svg");
}

* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: none !important;
}

@include breakpoint3 {
  .d-m-none {
    display: none;
  }
}

body {
  font-family: Gotham, sans-serif;
  font-weight: normal;
  color: $color-dark-3;
  line-height: 26px;
  font-size: 16px;
}

.container {
  padding-left: 10px;
  padding-right: 10px;
}

a {
  text-decoration: none;
  color: $color-blue-1;
  cursor: pointer;
}

a:hover {
  text-decoration: none;
}

.w-500 {
  font-weight: 500 !important;
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0;
}

p {
  margin: 0;
}

ol,
ul,
dl {
  list-style: none;
  margin-top: 0;
  margin-bottom: 0;
}

h1 {
  font-size: 40px;
}

a {
  &:hover {
    color: $color-white-1;
  }
}

h3 {
  font-size: 25px;
}

button:focus {
  outline-color: transparent;
}

.row {
  margin: 0;
}

figure {
  margin-bottom: 0;
}

input,
select {
  height: 47px;
}

input::placeholder {
  font-style: italic;
  font-weight: 300;
  font-size: 12px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input:-internal-autofill-previewed,
input:-internal-autofill-selected,
textarea:-internal-autofill-previewed,
textarea:-internal-autofill-selected,
select:-internal-autofill-previewed,
select:-internal-autofill-selected {
  background-color: #fff !important;
  background-image: none !important;
  color: rgb(0, 0, 0) !important;
}

@include breakpoint1 {
  .col-1,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-10,
  .col-11,
  .col-12,
  .col,
  .col-auto,
  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm,
  .col-sm-auto,
  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md,
  .col-md-auto,
  .col-lg-1,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg,
  .col-lg-auto,
  .col-xl-1,
  .col-xl-2,
  .col-xl-3,
  .col-xl-4,
  .col-xl-5,
  .col-xl-6,
  .col-xl-7,
  .col-xl-8,
  .col-xl-9,
  .col-xl-10,
  .col-xl-11,
  .col-xl-12,
  .col-xl {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1340px;
  }
}

.gm-style {
  display: none !important;
}

.position_custom {
  position: fixed;
  top: 56px;
  z-index: 999;
}