.financial-information-ph {
.cover-page{
  margin-bottom: 0;
}
  .financial {
    &__content {
      position: relative;
      top: -225px;
      left: 0;
      right: 0;
      display: block;
      height: 757px;
      background-color: $color-blue-8;
      padding-top: 62px;
      padding-left: 68px;
      padding-right: 68px;

      h1 {
        color: $color-blue-3;
        text-align: center;
      }

      p {
        padding-bottom: 30px;
        text-align: left;
        line-height: 35px;
        font-size: 20px;
      }

      img {
        position: absolute;
        top: 497px;
        width: 939px;
        height: 480px;
        left: 50%;

        transform: translateX(-50%);
        -webkit-transform: translateX(-50%);
        -moz-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        -o-transform: translateX(-50%);
      }
    }
  }
  .brand {
    background-image: url(./../../images/backg_final.png);
    background-color: #fafafa;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    padding-top: 442px;
        padding-bottom: 207px;
        margin-top: -228px;
    &__item {
      display: flex;
      align-items: center;
      height: 352px;
      background-color: $color-dark-2;
      &__content {
        img {
          position: relative;
          top: 50%;
          left: 50%;
          max-width: 100%;
          transform: translate(-50%, -50%);
          -webkit-transform: translate(-50%, -50%);
          -moz-transform: translate(-50%, -50%);
          -ms-transform: translate(-50%, -50%);
          -o-transform: translate(-50%, -50%);
          @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            // IE10+
            top: 300px;

          }
        }
        p {
          font-size: 20px;
          padding: 0 61px;
          text-align: center;
          height: 586px;
          display: flex;
          align-items: center;
          border: 5px solid $color-blue-1;
        }
      }
    }
  }

  @include breakpoint3 {
    .financial {
      &__content {
        position: relative;
        top: -93px;
        left: 0;
        right: 0;
        display: block;
        height: unset;
        background-color: $color-blue-8;
        padding-top: 33px;
        padding-left: 0;
        padding-right: 0;

        h1 {
          color: $color-blue-3;
          text-align: center;
          padding-bottom: 26px;
        }

        p {
          padding-bottom: 30px;
          line-height: 35px;
          font-size: 20px;
          text-align: left;
          padding-left: 9px;
          padding-right: 9px;
        }

        img {
          position: unset;
          width: 100%;
          height: auto;
          transform: unset;
          -webkit-transform: unset;
          -moz-transform: unset;
          -ms-transform: unset;
          -o-transform: unset;
        }
      }
    }
    .brand {
      margin-top: -91px;
      padding-top: 231px;
      padding-bottom: 231px;
      //margin-top: 0;
      //padding-top: 160px;
      //padding-bottom: 230px;
      &__item {
        display: flex;
        align-items: center;
        height: 258px;
        background-color: $color-dark-2;
        &__content {
          img {
           max-width:100%;
            position: relative;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            -webkit-transform: translate(-50%, -50%);
            -moz-transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
            -o-transform: translate(-50%, -50%);
          }
          p {
            padding: 0 23px;
            text-align: center;
            height: 425px;
            display: flex;
            align-items: center;
            border: 5px solid $color-blue-1;
            margin-top: 23px;
            margin-bottom: 37px;
          }
        }
      }
    }
  }
}
