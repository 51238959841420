.bleaching-trays-ph{
  background-color: $color-dark-4;
  .cover-page{
    margin-bottom: 26px;
  }
  .card-why {
    .card-body {
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
      margin-bottom: 26px;
      padding-top: 30px;
      margin-left: 15px;
      margin-right: 15px;
      padding-left: 36px;
      padding-right: 36px;
      background-color: $color-white-1;
      @include textLine(0);
      padding-bottom: 30px;
      p {
        text-align: left;
        padding-top: 20px;
        line-height: 35px;
      }
    }
  }
  @include  breakpoint3{
    background-color: unset;
    .card-why {
      .card-body {
        box-shadow: unset;
        margin-top: 0px;
        padding-top: 0px;
        margin-left: 0px;
        margin-right: 0px;
        padding-left: 0px;
        padding-right: 0;
        @include textLine(0);
        padding-bottom: 0px;
        p {
          padding-top: 10px;
          line-height: 35px;
        }
      }
    }
  }
}