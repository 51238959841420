.sleep-apnea-ph {
  .cover-page {
    margin-bottom: 0;
    .container{
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &__title{
      line-height: 46px;
      margin: 0 20%;
    }
  }

  .medical {
    background-color: $color-blue-11;

    img {
      padding: 54px 10px;
    }
  }

  .horizon {
    padding-top: 45px;
    padding-bottom: 60px;

    &__item:nth-child(1) {
      padding-right: 30px;
    }

    &__item:nth-child(2) {
      padding-left: 30px
    }

    @include textLine(25%);

    .content {
      margin-top: 20px;
      height: 295px;
      &__img{
        img{
          width: 100%;
        }
      }
    }

    img {
      width: 100%;
      height: 403px;
    }

    @media screen and (max-width: 1300px) {
      .content {
        height: 354px;
      }
    }
  }

  .horizon_custom {
    padding-top: 45px;
    padding-bottom: 50px;
    background-color: $color-dark-4;

    &__item:nth-child(1) {
      padding-right: 30px;
    }

    &__item:nth-child(2) {
      padding-left: 30px
    }

    @include textLine(0);

    .content {
      line-height: 35px;
      margin: 20px 0;
    }

    .time {
      color: #9B9B9B;
    }
  }

  .product {
    padding-left: 0;
    padding-right: 0;
    padding-top: 30px;
    padding-bottom: 25px;

    &__item {
      margin-bottom: 33px;
    }

    .description {
      line-height: 36px;
      margin-top: 20px;
      margin-bottom: 35px;
    }

    &__name {
      text-align: center;
      font-size: 16px;
      line-height: 35px;
      font-weight: 500;
      color: $color-blue-3;
      padding-bottom: 25px;
    }

    @include textLine(0);

    &__content {
      img {
        width: 100%;
        padding: 0 38px 0px 38px;
      }

      border: 0.5px solid $color-dark-6;
      border-radius: 3px;
      height: 100%;
      width: 100%;
      background-color: #F9F9F9;
    }
  }

  .video_thub {
    background-color: $color-dark-4;
    padding-top: 35px;
    padding-bottom: 78px;

    &__title {
      padding-bottom: 35px;
      text-align: center;
      font-size: 25px;
      line-height: 40px;
      color: $color-blue-3;
    }

    iframe {
      display: block;
      margin: auto;
      width: 76%;
      height: 563px;
    }
  }

  @include breakpoint3 {
    .cover-page{
      &__title{
        margin: 0 10%;
      }
    }
    .medical {
      background-color: unset;

      img {
        padding: 15px 10px;
      }
    }
    .horizon {
      padding-top: 15px;
      padding-bottom: 0px;

      &__item:nth-child(1) {
        padding-right: 0px;
        padding-left: 0px;

      }

      &__item:nth-child(2) {
        padding-left: 0px;
        padding-right: 0px;
      }

      @include textLine(0);

      .content {
        margin-top: 10px;
        height: unset;
      }

      img {
        width: 100%;
        margin-top: 20px;
        margin-bottom: 42px;
        height: unset;
      }
    }
    .horizon_custom {
      padding-top: 17px;
      padding-bottom: 7px;

      &__item:nth-child(1) {
        padding-right: 0px;
        padding-left: 0px
      }

      &__item:nth-child(2) {
        padding-left: 0px;
        padding-right: 0px;
      }

      @include textLine(0);

      .text_line {
        margin-bottom: -10px;
      }

      .content {
        line-height: 35px;
        margin: 20px 0;
      }

      .time {
        color: #9B9B9B;
      }
    }
    .product {
      &__item {
        margin-bottom: 15px;
      }

      .description {
        line-height: 36px;
        margin-top: 10px;
        margin-bottom: 15px;
      }

      &__name {
        padding-bottom: 15px;
      }

      &__content {
        img {
          padding: 0 22px;
        }
      }
    }
    .video_thub {
      &__title {
        padding-left: 50px;
        padding-right: 50px;
        padding-top: 0px;
        padding-bottom: 32px;
      }
      padding-bottom: 44px;
      iframe {
        padding: 0 10px;
        display: block;
        margin: auto;
        width: 100%;
        height: 209px;
      }
    }

  }
}