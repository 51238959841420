.early-prevention-ph {
.cover-page{
  margin-bottom: 0;
  &__title{
    line-height: 40px;
    padding-top: 80px;
  }
}
  .early_content {
    background-color: $color-dark-4;
    .text_content {
      margin-top: -142px;
      padding: 56px 83px;
      @include textLine(30px);
      background-color: $color-white-1;
      p {
        text-align: left;
        margin: 15px 0;
        line-height: 35px;
      }
    }
    .img_content {
      img{
        max-width: 100%;
      }
    }
  }
  .full_img {
    background-color: $color-dark-4;
    .content {
      .row--custom{  
          justify-content: flex-end;    
      }
      &--yellow{
        background-color: #fffdf6;
      }
      &__text {
        max-width: 600px;
        display: flex;
        align-items: center;
        @include textLine(30px);
        .text_line {
          margin-bottom: 50px;
        }
        &__body {
          position: relative;
          &::before {
            content: "";
            background-image: url(./../../icons/Vector.svg);
            width: 10px;
            height: 13px;
            position: absolute;
            top: 10px;
          }
          p {
            padding-left: 25px;
            text-align: left;
            line-height: 35px;
          } 
        }
        &--custom{
          max-width: 670px;
          .text_line {
            margin-bottom: 20px;
          }
          .content_other{
            p{
              text-align: left;
              line-height: 35px;
              margin-bottom: 20px;
            }
          }
        }
      }
      &__img {
        padding: 49px 0;
        img {
          width: 90%;
        }
        &--custom{
          img
         { float: right ;}
        }
      }
    }
  }
  @include breakpoint3{
    .cover-page{
      &__title{
        margin-top: -74px;
        line-height: 40px;
      }
    }
    .early_content {
      background-color: unset;
      .text_content {
        margin-top: 26px;
        padding: 0;
        @include textLine(0);
        p {
          text-align: left;
          margin: 10px 0;
          line-height: 35px;
        }
      }
      .img_content {
        img{
          width: 100%;
          margin-top: 35px;
        }
      }
    }
    .full_img {
      background-color:unset;
      border-bottom: 10px solid  #F5F5F5;
      .content {
        .row{
          padding: 0 10px;
        }
        &--yellow{
          background-color:unset;
        }
        &__text {
          &--custom{
            padding-left: 0;
            margin-right: 0;
            padding-right: 0;
          }
          max-width: unset;
          display: flex;
          align-items: unset;
          @include textLine(0);
          .text_line {
            margin-bottom: 10px;
          }
          &__body {
            position: relative;
            &::before {
              content: "";
              background-image: url(./../../icons/Vector.svg);
              width: 10px;
              height: 13px;
              position: absolute;
              top: 10px;
            }
            p {
              padding-left: 25px;
              text-align: left;
              line-height: 35px;
            }
           
          }
        }
        &__img {
          padding: 0;
          img {
            width: 100%;
            margin-top: 30px;
            margin-bottom: 20px;
          }
          &--custom{
            img
           { float: unset ;}
          }
        }
      }
    }
  }
}
