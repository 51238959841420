.two-phase-treament-ph {
  .cover-page {
    height: 692px;
    &__title {
      line-height: 40px;
      padding-top: 98px;
    }
    img {
      height: 692px;
    }
    margin-bottom: 0;
  }
  background-color: #fafafa;
  .orthodontics {
    .content {
      position: relative;
      margin-top: -499px;
      z-index: 2;
      padding: 93px 0;
      background: #d3edef;
      .text {
        @include textLine(0);
        display: flex;
        align-items: center;
        .text_content {
          p {
            line-height: 35px;
            padding-top: 20px;
          }
        }
      }

      &__background {
        position: absolute;
        width: 558px;
        height: 428px;
        top: 50%;
        transform: translateY(-50%);
        background: rgba(255, 255, 255, 0.5);
        z-index: -1;
        left: 35%;
      }
    }
  }
  .put {
    position: relative;
    &::before {
      content: "";
      height: 200%;
      width: 414px;
      background-color: #f6f6f6;
      position: absolute;
      left: 0;
      /* top: 0; */
      z-index: -8;
      bottom: 0;
    }
    padding-top: 91px;
    padding-bottom: 61px;
    .text {
      display: flex;
      justify-content: center;
      padding-left: 61px;
      padding-right: 129px;
      align-items: center;
      @include textLine(0);
      p {
        line-height: 35px;
        padding-top: 20px;
        text-align: left;
      }
    }
  }
  .phase {
    .content1 {
      background-color: #f6f6f6;
      padding: 50px 0;
      position: relative;
      &:before {
        content: "";
        background: rgba(232, 254, 253, 0.5);
        height: 100%;
        width: 908px;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
      }
      .text {
        @include textLine(0);
        .text_line{
          padding-bottom: 20px;
        }
        display: flex;
        align-items: center;

        h3 {
          margin-bottom: 23px;
        }

        p {
          margin-bottom: 15px;
          line-height: 35px;
        }
      }
      .img--custom {
        display: flex;
        justify-content: center;
      }
    }

    .content2 {
      padding-top: 40px;
      padding-bottom: 63px;
      position: relative;
      background-color: #fafafa;
      &::before {
        content: "";
        height: 100%;
        width: 414px;
        background-color: #f6f6f6;
        position: absolute;
        left: 0;
        /* top: 0; */
        z-index: 1;
        bottom: 0;
      }
      .text {
        @include textLine(0);
        .text_line{
          padding-bottom: 20px;

        }
        display: flex;
        align-items: center;
        position: relative;
        z-index: 1;

        h3 {
          margin-bottom: 23px;
        }

        p {
          margin-bottom: 15px;
          line-height: 35px;
          text-align: left;
        }
      }

      .img {
        img {
          float: right;
        }
      }
    }
  }

  @include breakpoint3 {
    .cover-page {
      &__title {
        line-height: 40px;
        padding-top: 0;
        margin-top: -178px;
      }
    }
    background-color: unset;
    .orthodontics {
      margin-top: -200px;
      background: #d3edef;
      .content {
        position: relative;
        margin-top: 0;
        z-index: 2;
        padding: 0 0 39px 0;
        .text {
          padding: 27px 0 0 15px;
          @include textLine(0);
          display: flex;
          align-items: center;
          background: rgba(255, 255, 255, 0.5);
          .text_content {
            p {
              padding-top: 0px;
              line-height: 35px;
            }
          }
        }

        .img {
          padding: 0;
          img {
            width: 100%;
            margin-top: -79px;
          }
        }

        &__background {
          width: 0px;
          height: 0px;
        }
      }
    }
    .put {
      padding-top: 0px;
      padding-bottom: 21px;
      &::before {
        content: unset;
      }
      .text {
        padding-left: 0px;
        padding-right: 0px;
        padding-top: 20px;
        @include textLine(0);

        p {
          padding-top: 7px;
          line-height: 35px;
          text-align: left;
        }
      }

      .img {
        img {
          width: 100%;
        }
      }
    }
    .phase {
      padding-bottom: 19px;
      border-bottom: 8px solid #f5f5f5;

      .content1 {
        padding: 0px 0;
        background-color: unset;
        &:before {
          content: unset;
        }

        .text {
          padding-top: 20px;
          @include textLine(0);
          .text_line{
            padding-bottom: 7px;
  
          }
          display: flex;
          align-items: center;

          h3 {
            margin-bottom: 7px;
          }

          p {
            margin-bottom: 10px;
            line-height: 35px;
          }
        }

        .img {
          &--custom {
            margin-left: 0;
          }
          img {
            width: 100%;
          }
        }
      }

      .content2 {
        padding-top: 20px;
        padding-bottom: 20px;
        background-color: unset;
        &::before {
          content: unset;
        }
        .text {
          padding-top: 20px;
          @include textLine(0);
          .text_line{
            padding-bottom: 7px;
  
          }
          display: flex;
          align-items: center;

          h3 {
            margin-bottom: 7px;
          }

          p {
            margin-bottom: 0px;
            line-height: 35px;
            text-align: left;
          }
        }

        .img {
          img {
            width: 100%;
          }
        }
      }
    }
  }
}
