.surgical-orthodontics-ph {
   .cover-page{
       margin-bottom: 0;

   }
    .content {
        .top {
            padding: 50px 0px;
            &__text {
                padding-right: 50px;
                display: flex;
                align-items: center;
                @include textLine(0);
                &--custom{
                    @include textLine(30%);
                }
                p {
                    line-height: 30px;
                    text-align: left;
                    margin-top: 18px;
                }
            }
            &__img {
                img {
                    float: right;
                }
            }
        }
        .bottom {
            padding: 50px 0;
            background-color: $color-blue-10;
            &__img {
                img {
                    float: left;
                }
            }
            &__text {
                @include textLine(30%);
                padding: 0 25px;
                align-items: center;
                display: flex;
                p{
                    line-height: 30px;
                    text-align: left;
                    margin-top: 18px;

                }
            }
        }
    }
    @include breakpoint3{
        .content {
            .top {
                padding: 40px 0 26px 0px;
                &__text {
                    padding-top: 30px;
                    padding-right: 0px;
                    display: flex;
                    align-items: center;
                    @include textLine(0);
                    &--custom{
                        @include textLine(0);
                    }
                    p {
                        line-height: 30px;
                        text-align: left;
                        margin-top: 10px;
                    }
                }
                &__img {
                    img {
                        width: 100%;
                        float: unset;
                    }
                }
            }
            .bottom {
                padding: 40px 0 26px 0px;
                background-color: $color-blue-10;
                &__img {
                    img {
                        width: 100%;
                        float: unset;
                    }
                }
                &__text {
                    @include textLine(0);
                    padding: 30px 0  0 0;
                    align-items: center;
                    display: flex;
                    p{
                        line-height: 30px;
                        text-align: left;
                        margin-top: 10px;

                    }
                }
            }
        }
    }
}
