.common-treatments-ph {
  .col-xl-6 {
    padding: 0 11px;
  }

  background-color: $color-dark-4;

  .cover-page {
    margin-bottom: 0;
  }

  .content {
    padding: 13px 0;

    &__item {
      position: relative;
      height: 372px;
      margin: 12px 0;
      text-align: center;
      padding: 20px 20px 54px 20px;
      background: #ffffff;
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);

      .text_line_custom {
        font-size: 25px;
        color: $color-blue-3;
        position: relative;
        text-transform: uppercase;
        display: inline-block;
        line-height: 40px;
        z-index: 99;
        margin: 10px 40px;

        &::after {
          content: "";
          width: 110%;
          height: 17px;
          background-color: #d8f1f3;
          display: block;
          position: absolute;
          top: 15px;
          z-index: -1;
          left: 50%;
          transform: translateX(-50%);
        }
      }

      .commonImg {
        position: absolute;
        bottom: 53px;
        left: 0;
        right: 0;
        width: 266px;
        margin: auto;
      }
    }
  }
  @include breakpoint3 {
    background-color: unset;
    .content {
      border-bottom: 9px solid #F5F5F5;
      padding: 23px 0 13px 0;
      &__item {
        position: unset;
        height: unset;
        margin: 0px 0 42px 0;
        text-align: left;
        padding: 0;
        background: #ffffff;
        box-shadow: unset;

        .text_line_custom {
          margin-bottom: 14px;
          margin-right: 0;
          margin-left: 0;
          font-size: 25px;
          color: $color-blue-3;
          position: relative;
          text-transform: uppercase;
          display: inline-block;
          line-height: 40px;
          z-index: 99;

          &::after {
            content: "";
            width: 100%;
            height: 17px;
            background-color: #d8f1f3;
            display: block;
            position: absolute;
            top: 21px;
            z-index: -1;
            left: -10px;
            transform: unset;
          }
        }
        .commonImg {
          position: relative;
          margin: auto;
          bottom: 0;
        }
      }
    }

  }
}
