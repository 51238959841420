.dentofacial-orthopedics-ph {
  .cover-page{
    margin-bottom: 0;
    &__title{
      line-height: 40px;
      padding-top: 115px;
    }
  }
  background-color: $color-dark-4;
  .content {
    position: relative;
    margin-top: -103px;
    margin-bottom: 27px;
    &__item {
      padding: 61px 70px 63px 70px;
      background-color: $color-white-1;
      margin: 0 59px;
      .top {
        padding-bottom: 30px;
        .text {
          @include textLine(0);
          display: flex;
          align-items: center;
          .text-content {
            p {
              line-height: 30px;
              margin-top: 20px;
              text-align: left;
            }
            float: right;
          }
        }
        .img {
          img {
            margin-left: 69px;
          }
        }
      }
      .bottom {
        text-align: left;
        p {
          padding-right: 15px;

        }
      }
    }
  }
  @include breakpoint3{
    .cover-page{
      &__title{
        margin-top: -74px;
        line-height: 40px;
        padding-top: 0;
      }
    }
    background-color: unset;
    .content {
      border-bottom: 18px solid $color-dark-4;
      margin-top: 0px;
      margin-bottom: 12px;
      &__item {
        padding:0;
        margin: 0px;
        .top {
          padding-bottom: 30px;
          .text {
            margin-top: 28px;
            .text-content {
              p {
                margin-top: 10px;
                margin-bottom: 15px;
              }
              float: right;
            }
          }
          .img {
            img {
              margin-left: 0px;
              width: 100%;
            }
          }
        }
        .bottom {
          text-align: left;
          p {
            padding-right: 0px;
            padding-bottom: 40px;
          }
        }
      }

    }
  }
}