.our-reviews-ph {
    .cover-page{
        margin-bottom: 0;
    }
    .border-custom{
        border-bottom: 1px solid #DDDDDD;
    }
    .container{
        &--custom{
         padding-bottom: 40px;
         //border-bottom: 1px solid #DDDDDD;
        }
       }
    .wrapper_tabcontent {
        margin-top: 40px;
        background-color: white;
        .content {
            &__item {
                .video {
                    img {
                        width: 100%;
                        position: relative;
                    }
                    @include playbutton(88px,20px);
                }
                .text {
                    p {
                        line-height: 35px;
                    }
                    &--custom {
                        padding-left: 0;
                    }
                    display: flex;
                    text-align: left;
                    align-items: center;
                    padding: 0 80px;
                }
            }
        }
    }
    /* Tab active */
    .button.active {
        background-color: #37677e;
        color: white;
        transition: all 0.2s;
        -webkit-transition: all 0.2s;
        -moz-transition: all 0.2s;
        -ms-transition: all 0.2s;
        -o-transition: all 0.2s;
    }
    .tabcontent {
        display: none;
    }
    @keyframes tabEffect {
        from {
            top: -40px;
        }
        to {
            top: 0px;
        }
    }
    .tabcontent.active {
        transition: all 1s ease;
        display: block;
    }
    @keyframes city {
        from {
            left: -150px;
        }
        to {
            left: -90px;
        }
    }
    /* Text*/
    @keyframes fadeEffect {
        from {
            opacity: 0;
            margin-left: 30px;
        }
        to {
            opacity: 1;
            margin-left: 0;
        }
    }
    .list_button {
        border-bottom: 10px solid $color-dark-4;
        margin-top: 29px;
        ul {
            display: flex;
            justify-content: center;
            padding-bottom: 29px;
            li {
                padding: 0 15px;
                button {
                    width: 44px;
                    height: 44px;
                    border-radius: 30px;
                    -webkit-border-radius: 30px;
                    -moz-border-radius: 30px;
                    -ms-border-radius: 30px;
                    -o-border-radius: 30px;
                }
            }
        }
    }
    @include breakpoint3 {
        .wrapper_tabcontent {
            margin-top: 20px;
            .content {
                &__item {
                    .video {
                        img {
                            margin-top: 20px;
                            width: 100%;
                        }
                    }
                    .text {
                        padding: 0;
                    }
                }
            }
        }
    }
}
