.contact-us-ph {
  background-color: $color-dark-4;
  .thub {
    padding-top: 77px;
    padding-bottom: 375px;
    background-color: #d3edef;
    .text {
      padding-left: 15px;
      position: relative;
      z-index: 99;
      &:after {
        content: "";
        background-color: #e0f2f4;
        width: 1068px;
        height: 412px;
        position: absolute;
        z-index: -1;
        right: 0;
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          // IE10+
          top: -32px;
        }
      }
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        // IE10+

      }
      display: flex;
      align-items: center;
      @include textLine(0);
      .text_line{
        font-size: 40px;
        &::after{
          top: 24px;
          background-color: #c5ebed;
        }
      }
      p {
        padding-top: 16px;
        line-height: 30px;
        padding-right: 20%;
      }
    }
    img {
      position: relative;
      z-index: 100;
    }
  }

  .form-contact {
    padding-top: 68px;
    background-color: $color-white-1;
    padding-right: 77px;
    padding-left: 77px;
    padding-bottom: 63px;
    margin-top: -300px;
    margin-bottom: 26px;
    .form-group {
      margin-bottom: 71px;
    }
    ::placeholder {
      color: #636363;
      font-size: 16px;
      font-style: normal;
       font-weight: normal;
      line-height: 30px;
    }
    .form-control {
      background-color: #e8f7f8;
      width: 100%;
      height: 55px;
      padding-left: 27px;
      padding-right: 23px;
      border: none;
    }
    select {
      -webkit-appearance: none;
      -moz-appearance: none;
      -ms-appearance: none;
      appearance: none;
      outline: 0;
      box-shadow: none;
      border: 0 !important;
      background-image: none;
      background-color: #e8f7f8;
      color: #636363;
      font-size: 16px;
      font-style: normal;
      width: 100%;
      height: 55px;
      padding-left: 27px;
      padding-right: 23px;
      font-weight: normal;
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        // IE10+
        font-weight: normal;
      }
       
    }
    .form-select::after {
      font-family: FontAwesome;
      content: '\f0dd';
      position: absolute;
      top: 43%;
      right: 35px;
      cursor: pointer;
      pointer-events: none;
      -webkit-transition: .25s all ease;
      -o-transition: .25s all ease;
      transition: .25s all ease;
      transform: translateY(-43%);
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        // IE10+
        content: "";
      }
    }
    textarea {
      background-color: #e8f7f8;
      width: 100%;
      padding-left: 30px;
      height: 55px;
      padding-top: 14px;
      padding-right: 23px;
    }
    button {
      width: 197px;
      height: 70px;
      font-size: 24px;
      color: #B1E4E8;
      background: #37677E;
      margin: auto;
    }
  }

  @include breakpoint3 {
    .container {
      padding: 0;
    }
    .thub {
      padding-top: 0px;
      padding-bottom: 375px;
      background-color: #d3edef;
      .text {
        padding-top: 35px;
        padding-right: 15px;
        &:after {
          content: unset;
          background-color: #e0f2f4;
          width: 1068px;
          height: 412px;
          position: absolute;
          z-index: -1;
          right: 0;
        }

        display: flex;
        align-items: center;
        @include textLine(0);
          .text_line{
            font-size: 40px;
            &::after{
              top: 24px;
              background-color: #c5ebed;
            }
          }
        p {
          line-height: 30px;
          font-size: 20px;
          padding-right: 0;
        }
      }

      img {
        width: 100%;
      }
    }
    .form-contact {
      padding-top: 40px;
      background-color: $color-white-1;
      padding-right: 0px;
      padding-left: 0px;
      padding-bottom: 66px;
      margin-top: -300px;
      margin-bottom: 10px;
      .form-item {
        margin-bottom: 26px;
      }
    }
    .top__content {
      padding-top: 34px;}
  }

}