.office-tour-ph {
  .cover-page {
    margin-bottom: 0;
    &__title {
      line-height: 40px;
      padding-top: 79px;
    }
  }
  .description {
    background-color: $color-blue-8;
    &__img {
      display: flex;
      justify-content: center;
      position: relative;
      img {
        margin-top: -174px;
      }
    }
    &__text {
      p {
        line-height: 35px;
        text-align: center;
        font-size: 20px;
        padding: 40px 132px 30px 132px;
      }
    }
  }
  .info {
    position: relative;
    padding-bottom: 62px;

    &:before {
      content: "";
      background-image: url("./../../images/tour7.png");
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 325px;
    }

    .carousel-indicators li {
      box-sizing: content-box;
      -ms-flex: 0 1 auto;
      flex: 0 1 auto;
      width: 55px;
      height: 6px;
      margin-right: 3px;
      margin-left: 3px;
      text-indent: -999px;
      cursor: pointer;
      background-color: $color-dark-5;
      background-clip: padding-box;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      transition: opacity 0.6s ease;
    }

    .carousel-indicators .active {
      background-color: $color-blue-3;
    }
    &__item {
      padding-top: 128px;
      padding-bottom: 90px;

      .left {
        &__title {
          h2 {
            font-family: "Zapfino", sans-serif;
            font-size: 60px;
            line-height: 35px;
            color: $color-blue-3;
          }

          p {
            font-size: 20px;
            line-height: 35px;
            padding-bottom: 20px;
          }
        }
      }
      .mid {
        display: flex;
        justify-content: center;

        &__content {
          img {
            margin-top: -28px;
          }

          .title {
            h2 {
              padding-top: 41px;
              font-family: "Zapfino", sans-serif;
              font-size: 60px;
              line-height: 35px;
              color: $color-blue-3;
            }
            p {
              font-size: 20px;
              line-height: 35px;
            }
          }
        }
      }
      .right {
        display: flex;
        justify-content: flex-end;
        &__content {
          .title {
            h2 {
              font-family: "Zapfino", sans-serif;
              font-size: 60px;
              line-height: 35px;
              color: $color-blue-3;
            }
            p {
              font-size: 20px;
              line-height: 35px;
              padding-bottom: 20px;
            }
          }
        }
      }
    }
  }
  .info-mobile {
    padding-top: 60px;
    padding-bottom: 45px;
    .carousel-indicators li {
      box-sizing: content-box;
      -ms-flex: 0 1 auto;
      flex: 0 1 auto;
      width: 55px;
      height: 6px;
      margin-right: 3px;
      margin-left: 3px;
      text-indent: -999px;
      cursor: pointer;
      background-color: $color-dark-5;
      background-clip: padding-box;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      transition: opacity 0.6s ease;
    }
    .carousel-indicators .active {
      background-color: $color-blue-3;
    }
    .carousel-item {
      padding-bottom: 36px;
      h2 {
        margin-top: 6px;
        font-family: "Zapfino", sans-serif;
        font-size: 60px;
        line-height: 35px;
        color: $color-blue-3;
      }

      p {
        font-size: 20px;
        line-height: 35px;
        padding-bottom: 20px;
      }

      img {
        width: 100%;
      }
    }
  }
  .form {
    background-size: cover;
    background-position: center;
    padding-top: 90px;
    padding-bottom: 80px;
    background-image: url(./../../images/tour6.png);
    text-align: center;
    h2 {
      color: $color-blue-3;
    }
    p {
      padding: 22px 40px;
      font-size: 20px;
      line-height: 40px;
    }
    &__button {
      .item {
        padding: 24px 71px 24px 37px;
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
        background-color: $color-white-1;
        margin-bottom: 21px;
        &--left {
          margin-right: 19px;
        }
        &--right {
          margin-left: 19px;
        }
        a {
          font-size: 20px;
          line-height: 40px;
           
          color: $color-black-1;
          &::before {
            content: "";
            background-image: url(./../../icons/download.svg);
            width: 25px;
            height: 32px;
            display: inline-block;
            margin-bottom: -9px;
            margin-right: 31px;
            background-position: center;
            background-size: cover;
          }
        }
      }
    }
  }
  @include breakpoint3 {
    .cover-page {
      &__title {
        padding-top: 0;
        margin-top: -74px;
      }
    }
    .description {
      background-color: $color-blue-8;
      &__img {
        img {
          margin-top: -120px;
          width: 100%;
        }
      }
      &__text {
        p {
          text-align: left;
          padding: 14px 0 54px 0;
        }
      }
    }
    .form {
      padding-top: 40px;
      padding-bottom: 40px;
      text-align: left;
      p {
        padding: 15px 0;
      }
      &__button {
        .item {
          width: 381px;
          height: 88px;
          padding: 0;
          display: flex;
          align-items: center;
          padding-left: 13px;
          box-shadow: 0px 1px 4px rgba(99, 90, 90, 0.1);
          background-color: $color-white-1;
          &--left {
            margin-right: 0;
          }
          &--right {
            margin-left: 0;
          }
          a {
            padding-left: 50px;
            padding-right: 20px;
            position: relative;
            &::before {
              top: 50%;
              left: 10px;
              transform: translateY(-50%);
              margin-right: 0px;
              position: absolute;
              -webkit-transform: translateY(-50%);
              -moz-transform: translateY(-50%);
              -ms-transform: translateY(-50%);
              -o-transform: translateY(-50%);
            }
          }
        }
      }
    }
  }
}
