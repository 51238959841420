.retainer-instructions-ph {
 .cover-page{
   margin-bottom: 0;
   &__title{
     line-height: 40px;
     padding-top: 84px;
   }
 }
  .list {
    padding-left: 50px;
    padding-bottom: 60px;
    background-color: $color-blue-9;
    position: relative;
    &::after {
      content: "";
      background-image: url("./../../images/retainer4.svg");
      position: absolute;
      right: 0;
      bottom: -68px;
      background-position: center;
      background-repeat: no-repeat;
      width: 17%;
      height: 496px;
    }
    .text_arrow {
      padding-bottom: 25px;
      position: relative;
      &::before {
        content: "";
        position: absolute;
        width: 14px;
        height: 11px;
        background-image: url(./../../icons/Vector.svg);
        display: inline-block;
        left: -33px;
        top: 8px;
        background-repeat: no-repeat;
        background-position: center;
      }
      p {
        line-height: 30px;
        font-size: 20px;
      }
    }
    &__row {
      &__left {
        padding-top: 53px;
      }
      &__right {
        img {
          width: 100%;
          margin-top: -27%;
        }
      }
    }
    &__text {
      text-align: left;
      padding-top: 50px;
      padding-right: 167px;
    }
  }
  //Responsive
  @include breakpoint3 {
       .cover-page{
      &__title{
        margin-top: -74px;
        line-height: 40px;
        padding-top: 0;
      }
    }
    .list {
      padding-left: 40px;
      &::after {
        content: unset;
      }
      padding-left: 0px;
      padding-bottom: 40px;
      .text_arrow {
        padding-bottom: 25px;
        p {
          padding-left: 0;
        }
      }
      &__row {
        &__left {
          padding-top: 53px;
          padding-left: 40px;
        }
      }
      &__text {
        text-align: left;
        padding-top: 25px;
        padding-right: 0;
        padding-left: 40px;
      }
    }
  }
}
