//color
//black
$color-black-1: #3b3b3b;
$color-black-2:#2D2D2D;
//white
$color-white-1: #ffffff;
//blue
$color-blue-1:#B1E4E8;
$color-blue-3:#37677E;
$color-blue-4:#525c5e;
$color-blue-5:#d8f1f3;
$color-blue-6:#e0f4f6;
$color-blue-7:#9ab1bb;
$color-blue-8:#DBEFF1;
$color-blue-9:#f4fffe;
$color-blue-10:#effaf9;
$color-blue-11:#F1FAFB;
//dark
$color-dark-1:#666666;
$color-dark-2:#F0F0F0;
$color-dark-3:#636363;
$color-dark-4:#f6f6f6;
$color-dark-5:#c4c4c4;
$color-dark-6:#E3E3E3;
//yellow
$color-yellow-1:#FFFDF6;
//responsive
$breakpoint1: 1440px;
$breakpoint4:1199px;
$breakpoint2: 1060px;
$breakpoint3: 992px;


