.why-braces-ph {
  background-color: $color-dark-4;
  .map{
    margin-top: 30px;
  }
  .cover-page{
    &__title{
      padding-top: 66px;
      line-height: 40px;
    }
    margin-bottom: 0;
  }
  .why {
    &__card {
      margin-left: 15px;
      margin-right: 15px;
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
      background-color: $color-white-1;
    }
    &__left {
      p {
        text-align: left;
        line-height: 32px;
        padding-top: 15px;
      }

      @include textLine(0);
      padding-top: 40px;
      padding-left: 36px;

      .custom {
        @include textLine(0);
        padding-top: 25px;
      }

      &__body {
        padding-top: 24px;
        line-height: 32px;
        color: $color-dark-3;
      }
    }
    &__right {
      padding-right: 0;
      margin-top: -135px;
      img {
        width: 91%;
        height: 648px;
        float: right;
      }
    }
    &__bottom {
      p{
        text-align: left;
        line-height: 34px;
        padding-right: 36px;
      }
      padding-left: 36px;
      padding-bottom: 40px;
      padding-top: 23px;
    }
  }
  .card-why {
    .card-body {
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
      margin-top: 22px;
      padding-top: 30px;
      margin-left: 15px;
      margin-right: 15px;
      padding-left: 36px;
      padding-right: 36px;
      background-color: $color-white-1;
      @include textLine(0);
      padding-bottom: 30px;
      p {
        text-align: left;
        padding-top: 20px;
        line-height: 35px;
      }
    }
  }
  //RESPONSIVE
  @include breakpoint3 {
    .cover-page{
      &__title{
        padding-top: 0;
        line-height: 40px;
        margin-top: -74px;
      }
     
    }
    background-color: unset;
    .why {
      &__card {
        margin-left: 0px;
        margin-right: 0px;
        box-shadow: unset;
      }
      &__left {
        p {
          line-height: 32px;
          padding-top: 10px;
        }
      @include textLine(0);
        padding-top: 20px;
        padding-left: 0px;
        .custom {
          @include textLine(0);
          padding-top: 25px;
        }
        &__body {
          padding-top: 24px;
          line-height: 32px;
          color: $color-dark-3;
        }
      }
      &__right {
        padding-right: 0;
        margin-top: -118px;
        padding-left: 0;
        img {
          width: 100%;
          height: 414px;
          float: unset;
        }
      }
      &__bottom {
        padding-left: 0px;
        line-height: 34px;
        padding-bottom: 0px;
        padding-top: 23px;
        p{padding-right: 0;}
      }
    }
    .card-why {
      margin-top: 23px;
      .card-body {
        box-shadow: unset;
        margin-top: 0px;
        padding-top: 0px;
        margin-left: 0px;
        margin-right: 0px;
        padding-left: 0px;
        padding-right: 0;
        @include textLine(0);
        // &--content1 {
        //   @include text_line(102%);
        // }
        // &--content2 {
        //   @include text_line(92%);
        // }
        // &--content3 {
        //   @include text_line(92%);
        // }

        // &--content4 {
        //   @include text_line(102%);
        // }

        // &--content5 {
        //   @include text_line(102%);
        // }

        // &--content6 {
        //   @include text_line(102%);
        // }

        // &--content7 {
        //   @include text_line(70%);
        // }

        // &--content8 {
        //   @include text_line(102%);
        // }

        // &--content9 {
        //   @include text_line(102%);
        // }

        // &--content10 {
        //   @include text_line(102%);
        // }

        padding-bottom: 0px;
        p {
          padding-top: 10px;
          line-height: 35px;
        }
      }
    }
  }
  .map{
    border-top: 10px solid  #F5F5F5;
  }
}