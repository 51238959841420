.office-visits-ph {
    .cover-page{
        margin-bottom: 0;
        &__title{
            line-height: 40px;
            padding-top: 108px;
        }
    }
    .office_visit {
        margin-top: -120px;
        position: relative;
        .content {
            &__left {
                img {
                    width: 100%;
                }
            }
            &__right {
                padding-bottom: 52px;
                position: relative;
                z-index: -1;
                @include text_line(273px);
                padding-top: 18px;
                padding-left: 66px;
                padding-right: 70px;
                margin-top: auto;
                background-color: white;
                p {
                    line-height: 30px;
                    margin-top: 28px;
                }
            }
        }
        &__background {
            background-color: $color-blue-6;
            width: 100%;
            height: 367px;
            position: absolute;
            z-index: -2;
            top: 109px;
            background-image: url(./../../images/office2.png);
            background-size: contain;
            background-repeat: no-repeat;
            background-position: right;
        }
    }
    .smile {
        margin-top: -229px;
        padding-bottom: 66px;
        background-image: url(./../../images/smile2.png);
        // background-position-x: -180px;
        // background-position-y: 44px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
        z-index: -2;
        .content {
            padding-top: 311px;
            padding-bottom: 100px;
        }
        &__left {
            @include text_line(640px);
            .smile_left__top {
                margin-top: 20px;
            }
            p {
                line-height: 35px;
            }
            .list {
                padding: 21px 0;
                p {
                    &::before {
                        content: "";
                        background-image: url(./../../icons/Vector.svg);
                        width: 11px;
                        height: 13px;
                        display: inline-block;
                        margin-right: 20px;
                        background-size: cover;
                        background-repeat: no-repeat;
                        background-position: center;
                    }
                }
            }
        }
        &__right {
            img {
                width: 90%;
                float: right;
            }
        }
    }
    .happen {
        text-align: left;
        padding-bottom: 43px;
        padding-top: 53px;
        background-image: url(./../../images/background.png);
        background-color: #fafafa;
        background-position: center;
        background-size: cover;
        &__1 {
            &__left {
                padding: 90px;
                @include text_line(483px);
                p {
                    line-height: 35px;
                    margin-top: 16px;
                }
            }
            &__right {
                img {
                    width: 78%;
                    margin-left: 40px;

                }
            }
        }
        &__2 {
            padding: 45px 0;
            &__left {
                img {
                    width: 78%;
                    margin-left: 40px;
                }
            }
            &__right {
                padding: 90px;
                padding-left: 30px;
                @include text_line(483px);
                p {
                    line-height: 35px;
                    margin-top: 16px;
                }
            }
        }

    }
    @include breakpoint3 {
        .cover-page{
            &__title{
                line-height: 40px;
                padding-top: 0;
                margin-top: -74px;
            }
        }
        .office_visit {
            margin-top: -174x;
            position: relative;
            .content {
                &__left {
                    img {
                        width: 100%;
                    }
                }
                &__right {
                    padding-top: 18px;
                    padding-left: 0;
                    padding-right: 0;
                    margin-top: auto;
                    padding-bottom: 20px;
                    p {
                        line-height: 30px;
                        padding-left: 0;
                        margin-top: 10px;
                    }
                }
            }
            &__background {
                height: 176px;
                top: 109px;
                background-image: unset;
            }
        }
        .smile {
            background-image: unset;
            margin-top: 0;
            .content {
                padding-top: 0;
                padding-bottom: 0;
            }
            &__left {
                .smile_left__top {
                    margin-top: 0px;
                }
                @include text_line(100%);
                p {
                    line-height: 35px;
                    padding-top: 10px;
                }
                .list {
                    padding: 0;
                }
            }
            &__right {
                img {
                    width: 100%;
                    float: unset;
                    margin-bottom: 20px;
                }
            }
        }
        .happen {
            padding-bottom: 10px;
            border-bottom: 10px solid  #F5F5F5;;
            padding-top: 0px;
            margin-top: -40px;
            background-image: unset;
            &__1 {
                &__left {
                    padding: 0;
                    padding-bottom: 23px;
                    @include text_line(100%);
                    p {
                        line-height: 35px;
                        margin-top: 10px;
                    }
                }
                &__right {
                    padding-bottom: 23px;
                    img {
                        width: 100%;
                        margin-left: 0;
                    }
                }
            }
            &__2 {
                padding: 0;
                &__left {
                    padding-bottom: 23px;
                    img {
                        width: 100%;
                        margin-left: 0;
                    }
                }
                &__right {
                    padding: 0;
                    padding-bottom: 46px;
                    @include text_line(100%);
                    p {
                        line-height: 35px;
                        margin-top: 10px;
                    }
                }
            }
        }
    }
}
