.home-ph {
  .cover_home {
    position: relative;

    video {
      height: 710px;
      width: 100%;
      object-fit: cover;
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        // IE10+
      }
    }

    &__content {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;

      &__title {
        font-size: 57px;
        font-weight: normal;
        font-family: "Amada", sans-serif;
        color: $color-white-1;
        @media screen and (min-width: 992px), (-ms-high-contrast: none), (-ms-high-contrast: active) {
          // IE10+
          padding-right: 20px;
        }
      }

      &__body {
        color: $color-white-1;
        font-size: 16px;
        text-align: center;
        padding: 10px 29%;
        @media screen and (min-width: 992px), (-ms-high-contrast: none), (-ms-high-contrast: active) {
          // IE10+
          max-width: 98%;
        }
      }

      .button--cover {
        padding: 13px 0;
        width: 223px;
        height: 50px;
        background-color: $color-blue-3;
        color: $color-blue-1;
        margin-bottom: 41px;
      }
    }

    &__rating {
      width: 204px;
      position: absolute;
      right: 31px;
      bottom: 61px;
      background: #fff;
      padding-top: 10px;
      padding-bottom: 10px;
      text-align: center;

      &__title {
        background: #fef7e9;
        font-size: 14px;

        line-height: 19px;
        color: #f6ad01;
        margin: 0 25px;
        letter-spacing: 4px;
      }

      &__number {
        margin-top: 9px;
        display: flex;
        margin-left: 30px;

        &__1 {
          font-size: 34px;
          color: #f6ad01;
        }

        &__2 {
          color: $color-black-1;
          font-size: 14px;
          margin-top: 9px;
          margin-left: 3px;
          font-weight: 400;
        }
      }

      &__stars {
        display: flex;
        margin-left: 30px;

        .star {
          width: 20px;
          height: 20px;
          margin-right: 3px;
          background-image: url(./../../icons/star.svg);
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
        }
      }

      &__learnmore {
        color: #37677e;
        margin-left: 32px;
        margin-top: 9px;
        font-size: 10px;
        line-height: 19px;
        margin-left: -65px;
      }
    }
  }

  .infomation {
    padding-bottom: 112px;
    padding-top: 100px;
    position: relative;

    &__title {
      display: none;
      text-align: center;

      &__name {
        font-size: 50px;
        font-weight: normal;
        font-family: "Pacifico", sans-serif;
        color: $color-blue-3;
        margin-top: 10px;
      }

      &__des {
        font-size: 25px;
        font-weight: normal;
        font-family: "Pacifico", sans-serif;
        color: $color-dark-1;
        margin-top: 25px;
        margin-bottom: 40px;
      }
    }

    &::after {
      content: "";
      background-image: url(./../../icons/dot3.svg);
      width: 88px;
      height: 378px;
      position: absolute;
      top: 10px;
      right: 10px;
    }

    &::before {
      content: "";
      background-image: url(./../../icons/dot9.svg);
      width: 445px;
      height: 57px;
      position: absolute;
      bottom: 20px;
      left: 16px;
    }

    &__left {
      &__img {
        display: flex;

        &__1 {
          width: 88px;
          height: 378px;
          margin-right: -21px;
        }

        &__2 {
          width: 419px;
          height: 487px;
          @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            // IE10+
            max-width: 100%;
          }
        }

        &__3 {
          width: 222px;
          height: 270px;
          margin-left: 42px;
          margin-top: auto;
          @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            // IE10+
            //width: 100%;
            margin-right: -117px;
            margin-left: 42px;
          }
        }
      }

      &__content {
        text-align: center;
        padding: 38px 75px 0px 75px;
        margin-right: -150px;

        &__text {
          color: #666666;
          line-height: 30px;
          margin-bottom: 30px;
        }

        a {
          padding: 13px 41px;
          font-size: 18px;
        }
      }
    }

    &__right {
      &__name {
        font-size: 70px;
        font-weight: normal;
        font-family: "Pacifico", sans-serif;
        color: $color-blue-3;
        margin-top: 10px;
        margin-left: -51px;
      }

      &__des {
        font-size: 40px;
        font-weight: normal;
        font-family: "Pacifico", sans-serif;
        color: $color-dark-1;
        margin-top: 25px;
      }

      &__img {
        width: 402px;
        height: 500px;
        margin-left: 20%;
        margin-top: 72px;
      }

      &__content {
        p {
          text-align: left;
        }

        display: none;
      }

      &__mobile {
        display: none;
      }
    }
  }

  .home_services {
    padding-top: 102px;
    padding-bottom: 120px;
    background-image: url(./../../images/home_ourservices.png);
    background-position: bottom;
    background-size: cover;
    background-repeat: no-repeat;

    &__left {
      padding-top: 102px;

      &__content {
        &__top {
          position: relative;

          &__img {
            width: 100%;
          }

          .dark_background {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: rgba(0, 0, 0, 0.4);
            cursor: pointer;

            &:hover {
              background: linear-gradient(180deg, rgba(0, 0, 0, 0) 71.74%, rgba(0, 0, 0, 0.5) 100%);
              transition: all .3s;
            }
          }

          p {
            color: $color-white-1;
            position: absolute;
            bottom: 14px;
            left: 23px;
            font-size: 20px;
          }
        }

        &__bottom {
          margin-top: 15px;

          &__left {
            padding-right: 7px;

            .dark_background {
              position: absolute;
              top: 0;
              left: 0;
              right: 7px;
              bottom: 0;
              background: rgba(0, 0, 0, 0.4);
              cursor: pointer;

              &:hover {
                background: linear-gradient(180deg, rgba(0, 0, 0, 0) 71.74%, rgba(0, 0, 0, 0.5) 100%);
                transition: all .3s;
              }
            }
          }

          &__right {
            padding-left: 7px;

            .dark_background {
              position: absolute;
              top: 0;
              left: 7px;
              right: 0;
              bottom: 0;
              background: rgba(0, 0, 0, 0.4);
              cursor: pointer;

              &:hover {
                background: linear-gradient(180deg, rgba(0, 0, 0, 0) 71.74%, rgba(0, 0, 0, 0.5) 100%);
                transition: all .3s;
              }
            }
          }

          img {
            width: 100%;
            position: relative;
          }

          p {
            color: $color-white-1;
            position: absolute;
            bottom: 14px;
            left: 23px;
            font-size: 20px;
          }
        }
      }
    }

    &__right {
      padding-left: 0;

      &__top {
        position: relative;

        img {
          position: relative;
          width: 90%;
        }

        .dark_background {
          width: 90%;
          position: absolute;
          top: 0;
          left: 0;
          right: 0px;
          bottom: 0;
          background: rgba(0, 0, 0, 0.4);
          cursor: pointer;

          &:hover {
            background: linear-gradient(180deg, rgba(0, 0, 0, 0) 71.74%, rgba(0, 0, 0, 0.5) 100%);
            transition: all .3s;
          }
        }

        p {
          color: $color-white-1;
          position: absolute;
          bottom: 14px;
          left: 23px;
          font-size: 20px;
        }
      }

      &__bottom {
        text-align: center;
        margin-top: 83px;
        padding: 0 42px 0 0;
        margin-left: 70px;

        &__title {
          font-family: "Zapfino", sans-serif;
          font-size: 100px;
          position: relative;
          color: #37677e;
          line-height: 50px;

          &::after {
            content: "";
            height: 40px;
            width: 1px;
            position: absolute;
            left: 50%;
            transform: translateY(-50%);
            background: rgba(55, 103, 126, 0.5);
            top: -50px;
          }
        }

        &__body {
          color: $color-dark-1;
        }
      }
    }
  }

  .service_mobile {
    background-image: url(./../../images/home_ourservices.png);
    background-position: center;
    background-repeat: no-repeat;
    padding-bottom: 58px;

    &__title {
      font-family: "Zapfino", sans-serif;
      font-size: 80px;
      text-align: center;
      padding-top: 20px;
      color: #37677e;
    }

    &__body {
      font-size: 16px;
      color: $color-dark-1;
      padding-left: 15px;
      padding-bottom: 28px;
      padding-right: 15px;
    }

    &__carousel {
      .item {
        &::after{
          content: "";
          position: absolute;
          top: 0;
          left: 15px;
          right: 0px;
          bottom: 0;
          background: rgba(0, 0, 0, 0.4);
          cursor: pointer
        }
        padding-left: 15px;
      }

      img {
        width: 100%;
        height: 355px !important;
        position: relative;
        object-fit: cover;

      }
      .active .item {
        &::after {
          background: transparent;
        }

        //&:hover {
        //  //background: linear-gradient(180deg, rgba(0, 0, 0, 0) 71.74%, rgba(0, 0, 0, 0.5) 100%);
        //  transition: all .3s;
        //}
      }
      p {
        position: absolute;
        left: 39px;
        bottom: 18px;
        font-size: 20px;
        color: white;
      }
    }
  }

  .story {
    margin-top: -15px;
    min-height: 710px;
    background-image: url("../../images/bacgound_story.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    &__left {
      position: relative;

      h2 {
        font-family: Zapfino;
        font-style: italic;
        font-weight: normal;
        font-size: 100px;
        padding-top: 94px;
        color: #37677e;
        float: right;
      }

      .line {
        height: 112px;
        width: 1px;
        position: absolute;
        right: 0;
        top: 180px;
        background: $color-blue-7;
      }
    }

    &__right {
      margin-top: 91px;

      &__item {
        img {
          width: 180px;
          border-radius: 50%;
          position: relative;
          left: 50%;
          transform: translateX(-50%);
        }

        &__stars {
          margin-top: 23px;
          display: flex;
          justify-content: center;

          .star {
            margin: 0 15px;
            width: 23px;
            height: 23px;
            background-image: url(./../../icons/Star2.svg);
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
          }
        }

        &__name {
          margin-top: 32px;
          text-align: center;
          font-family: Zapfino;
          font-weight: normal;
          font-size: 40px;
          color: #37677e;
        }

        &__body {
          margin-top: 23px;
          text-align: center;
          height: 230px;
          padding: 0 44px;
          color: $color-dark-1;
        }
      }

      .arrow {
        margin-top: 42px;
        text-align: center;
        padding-bottom: 20px;

        &__prev {
          &::before {
            content: "";
            background-image: url(./../../icons/next.svg);
            width: 27px;
            height: 10px;
            display: inline-block;
            margin: 0 7px;
          }
        }

        &__next {
          &::after {
            content: "";
            background-image: url(./../../icons/prev.svg);
            width: 27px;
            height: 10px;
            display: inline-block;
            margin: 0 7px;
          }
        }
      }
    }
  }

  .follow {
    padding-bottom: 81px;
    background: $color-dark-4;
    z-index: 9;
    position: relative;

    &::before {
      content: "";
      background-image: url(./../../icons/dot4.svg);
      position: absolute;
      left: 10px;
      z-index: 9;
      width: 218px;
      height: 129px;
      bottom: 150px;
    }

    &::after {
      content: "";
      background-image: url(./../../icons/dot6.svg);
      position: absolute;
      right: 10px;
      z-index: 9;
      width: 215px;
      height: 93px;
      top: 150px;
    }

    &__left {
      padding-top: 50px;

      &__top,
      &__bottom {
        display: flex;

        img {
          width: 100%;
        }
      }

      &__top {
        margin-bottom: 15px;

        .img_left {
          margin-top: auto;
          padding-right: 0;

          img {
            width: 80%;
            float: right;
          }
        }

        .img_right {
          img {
            width: 80%;
          }
        }
      }

      &__bottom {
        margin-left: 33.4%;

        .img_left {
          img {
            width: 100%;
            height: auto;
            float: right;
          }
        }

        .img_right {
          padding-left: 0;

          img {
            width: 100%;
            height: auto;
          }
        }
      }
    }

    &__right {
      position: relative;
      text-align: center;

      &__content {
        margin-left: 107px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
      }

      i {
        font-size: 56px;
        color: $color-white-1;
      }

      p {
        font-size: 18px;
        line-height: 39px;
        color: $color-white-1;
        margin-top: 60px;
        font-weight: 500;
      }

      .line {
        width: 1px;
        height: 43px;
        background-color: $color-white-1;
        position: absolute;
        right: 50%;
        transform: translateX(-50%);
        -webkit-transform: translateX(-50%);
        -moz-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        -o-transform: translateX(-50%);
        margin: 10px 0;
      }
    }

    &__background {
      background-color: $color-blue-1;
      min-height: 498px;
      position: absolute;
      top: 50%;
      z-index: -1;
      width: 100%;
      transform: translateY(-50%);
    }
  }

  //responsive
  @include breakpoint5 {
    .infomation {
      padding-top: 17px;
      padding-bottom: 37px;

      &__title {
        display: block;
      }

      &::after {
        content: unset;
      }

      &::before {
        content: unset;
      }

      &__left {
        &__img {
          display: block;

          &__1 {
            display: none;
          }

          &__2 {
            width: 100%;
            height: 100%;
          }

          &__3 {
            display: none;
          }
        }

        &__content {
          display: none;
        }
      }

      &__right {
        &__name {
          display: none;
        }

        &__des {
          display: none;
        }

        &__img {
          display: none;
        }

        &__mobile {
          display: flex;
          margin-top: 35px;

          .img1 {
            width: 100%;
            height: 100%;
            margin-left: auto;
            margin-top: 0px;
            padding-right: 15px;
          }

          .img2 {
            display: block;
            height: 100%;
            width: 100%;
            padding-left: 15px;
          }
        }

        &__content {
          margin-top: 40px;
          display: block;
          text-align: center;

          &__text {
            color: #666666;

            line-height: 30px;
            text-align: left;
          }

          button {
            a {
              padding: 20px 10%;
            }

            width: 100%;
            height: 47px;
            margin-top: 20px;
            font-size: 18px;
          }
        }
      }
    }
    .follow {
      &__background {
        background-color: $color-blue-1;
        min-height: 340px;
        position: absolute;
        top: 42%;
        z-index: -1;
        width: 100%;
        transform: translateY(-50%);
      }
    }
  }
  @include breakpoint3 {
    .cover_home {
      video {
        height: 496px;
      }

      position: relative;

      &__img {
        height: 496px;
        background-image: url(./../../images/home_thumbnail_mobile.png);
      }

      &__content {
        &__body {
          padding: 10px 0;
          @media screen and (max-width: 992px), (-ms-high-contrast: none), (-ms-high-contrast: active) {
            // IE10+
            max-width: 80%;
          }
        }

        button {
          margin-bottom: 17px;
        }
      }
    }
    .follow {
      background: $color-blue-1;
      margin-top: 160px;
      // background: unset;
      position: relative;
      padding-bottom: 0;

      &::before {
        content: unset;
      }

      &::after {
        content: unset;
      }

      &__left {
        padding-top: 0;
        margin-top: -105px;

        &__top,
        &__bottom {
          display: flex;

          img {
            width: 100%;
          }
        }

        &__top {
          margin-bottom: 15px;

          .img_left {
            margin-top: auto;

            img {
              width: 100%;
              height: auto;
            }
          }

          .img_right {
            img {
              width: 100%;
              height: auto;
            }
          }
        }

        &__bottom {
          margin-left: 0;

          .img_left {
            img {
              width: 100%;
              height: auto;
            }
          }

          .img_right {
            img {
              width: 100%;
              height: auto;
            }
          }
        }
      }

      &__right {
        margin-top: 15px;
        margin-bottom: -30px;

        &__content {
          margin-left: 0;
          position: unset;
          margin-top: 130px;
        }
      }

      &__background {
        min-height: 0;
        top: 0;
      }
    }
    .story {
      background-image: unset;
      margin-top: -10px;
      background: linear-gradient(0deg, #f3f3f3, #f3f3f3);
      min-height: unset;

      &__left {
        position: relative;

        h2 {
          //display: none;
          font-size: 80px;
          padding-top: 20px;
          color: #37677e;
          float: unset;
          text-align: center;
          margin-bottom: -110px;
        }

        .line {
          position: unset;
          margin-left: 50%;
          margin-top: 96px;
        }
      }

      &__right {
        margin-top: 19px;

        &__item {
          img {
            width: 180px;
            position: relative;
            left: 50%;
            transform: translateX(-50%);
          }

          &__stars {
            margin-top: 23px;
            display: flex;
            justify-content: center;

            .star {
              margin: 0 15px;
              width: 23px;
              height: 23px;
              background-image: url(./../../icons/Star2.svg);
              background-position: center;
              background-size: cover;
              background-repeat: no-repeat;
            }
          }

          &__name {
            margin-top: 32px;
            text-align: center;
            font-family: Zapfino;
            font-weight: normal;
            font-size: 40px;
          }

          &__body {
            margin-top: 23px;
            text-align: left;
            height: unset;
            padding: 0;
          }
        }

        .arrow {
          margin-top: 29px;
          margin-bottom: 29px;
          text-align: center;

          &__prev {
            &::before {
              content: "";
              background-image: url(./../../icons/next.svg);
              width: 27px;
              height: 10px;
              display: inline-block;
              margin: 0 7px;
            }
          }

          &__next {
            &::after {
              content: "";
              background-image: url(./../../icons/prev.svg);
              width: 27px;
              height: 10px;
              display: inline-block;
              margin: 0 7px;
            }
          }
        }
      }
    }
  }
}
